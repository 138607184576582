import { Box } from "@mui/material";
const Logo = (props: any) => {
  return (
    <Box {...props}>
      <a href="https://www.loyac.org/default_en.aspx">
        <img
          src="/icons/loyac-logo.png"
          alt="Loyac logo"
          title="Main website"
          width="80px"
        />
      </a>
    </Box>
  );
};

export default Logo;
