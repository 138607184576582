import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  alertQueue: [], // An array to hold alert objects with message and other info
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    enqueueAlert: (state, action: PayloadAction<any>) => {
      //@ts-ignore
      state.alertQueue.push(action.payload);
    },
    dequeueAlert: (state) => {
      state.alertQueue.shift();
    },
  },
});

export const { enqueueAlert, dequeueAlert } = alertSlice.actions;

export default alertSlice.reducer;
