import instance from "./instance";
import * as actionTypes from "./actionTypes";

export const FetchGovernorates = () => async (dispatch) => {
  try {
    const res = await instance.get(`/governorates/`);
    const governorates = res.data;

    dispatch({ type: actionTypes.FETCH_GOVERNORATES, payload: governorates });

    return governorates;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchAreas = () => async (dispatch) => {
  try {
    const res = await instance.get(`/areas/`);
    const areas = res.data;

    dispatch({ type: actionTypes.FETCH_AREAS, payload: areas });

    return areas;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchUniversities = (uniLocation) => async (dispatch) => {
  try {
    const response = uniLocation
      ? await instance.get(`/universities/?uni_location=${uniLocation}`)
      : await instance.get("/universities/");
    const universities = response.data;

    dispatch({ type: actionTypes.FETCH_UNIVERSITIES, payload: universities });
    return universities;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const fetchFaculties = () => async (dispatch) => {
  try {
    const res = await instance.get(`/faculties/`);
    const faculties = res.data;

    dispatch({ type: actionTypes.FETCH_FACULTIES, payload: faculties });
    return faculties;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchMajors = () => async (dispatch) => {
  try {
    const res = await instance.get(`/majors/`);
    const majors = res.data;

    dispatch({ type: actionTypes.FETCH_MAJORS, payload: majors });
    return majors;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchNationalities = () => async (dispatch) => {
  try {
    const res = await instance.get(`/nationalities/`);
    const nationalities = res.data;

    dispatch({ type: actionTypes.FETCH_NATIONALITIES, payload: nationalities });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
