// Actions
import {
  FETCH_AREAS,
  FETCH_MAJORS,
  FETCH_FACULTIES,
  FETCH_GOVERNORATES,
  FETCH_UNIVERSITIES,
  FETCH_NATIONALITIES,
} from "../actions/actionTypes";

const initialState = {
  areas: [],
  areasLoading: true,
  majors: [],
  majorsLoading: true,
  governorates: [],
  governoratesLoading: true,
  universities: [],
  universitiesLoading: true,
  faculties: [],
  facultiesLoading: true,
  nationalities: [],
  nationalitiesLoading: true,
};

export const tablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GOVERNORATES:
      return {
        ...state,
        governorates: action.payload,
        governoratesLoading: false,
      };
    case FETCH_AREAS:
      return {
        ...state,
        areas: action.payload,
        areasLoading: false,
      };
    case FETCH_UNIVERSITIES:
      return {
        ...state,
        universities: action.payload,
        universitiesLoading: false,
      };
    case FETCH_FACULTIES:
      return {
        ...state,
        faculties: action.payload,
        facultiesLoading: false,
      };
    case FETCH_MAJORS:
      return {
        ...state,
        majors: action.payload,
        majorsLoading: false,
      };
    case FETCH_NATIONALITIES:
      return {
        ...state,
        nationalities: action.payload,
        nationalitiesLoading: false,
      };
    default:
      return state;
  }
};

export default tablesReducer;
