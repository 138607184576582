import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { defaultColors } from "../themes/main/default";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";

const LanguageSwitcherButton = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const activeLang = i18n.language;

  const handleChangeLang = useCallback(() => {
    // Check if the current URL path contains /ar/
    const isAR = location.pathname.includes("/ar/");

    // grab the location search and state
    const search = location.search;

    const newPath = isAR
      ? location.pathname.replace("/ar/", "/en/")
      : location.pathname.replace("/en/", "/ar/");

    history.push(`${newPath}${search}`, location.state);
  }, [history, location.pathname, location.search, location.state]);

  return (
    <Tooltip title={t("common.switchLang")}>
      <IconButton
        onClick={handleChangeLang}
        aria-label="Language"
        sx={{ color: defaultColors.dark0 }}
      >
        <LanguageOutlinedIcon titleAccess={activeLang === "en" ? "Ar" : "En"} />
      </IconButton>
    </Tooltip>
  );
};

export default LanguageSwitcherButton;
