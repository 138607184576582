import React from "react";
import { Container, SxProps, Theme } from "@mui/material";

interface Props {
  children: React.ReactNode;
  maxWidth?: any;
  sx?: SxProps<Theme>;
}

const CustomContainer = ({ children, maxWidth = "lg", sx }: Props) => {
  return (
    <Container maxWidth={maxWidth} sx={{ ...sx }}>
      {children}
    </Container>
  );
};

export default CustomContainer;
