import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { apiErrorCatcher } from "./common-action-utils";
import { patchRequestAPI } from "redux/services/inquiries";

const initialState = {
    loading: false,
    formLoading: false,
    error: null,
    formSuccess: false,
};

export const patchRequest = createAsyncThunk(
    "api/patchRequest",
    async (payload: any, thunkAPI) => {
      const { id, data } = payload;
  
      try {
        const response = await patchRequestAPI(id, data);
        return response.data;
      } catch (error: any) {
        apiErrorCatcher(error, thunkAPI.dispatch);
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  );

const inquiriesSlice = createSlice({
    name: "inquiriesSlice",
    initialState,
    reducers:{},
    extraReducers: (builder)=> {
        builder
            // Handle the patchRegistration async thunk
      .addCase(patchRequest.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        patchRequest.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(
        patchRequest.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
          state.formSuccess = false;
        }
      )


    }
})

export default inquiriesSlice.reducer