import axios from "redux/actions/instance";

// this uri is used by non logged in AUTH api forgot password, singup, login
const authUri = "/allauth/app/v1/auth/";

export const forgotPasswordAPI = (payload: any) =>
  axios.post(`${authUri}password/request`, payload);

export const resetPasswordFromKeyAPI = (payload: any) =>
  axios.post(`${authUri}password/reset`, payload);

export const loginAPI = (payload: any) => {
  axios.defaults.headers.common.Authorization = "";
  return axios.post(`${authUri}login`, payload);
};
