// Actions
import {
  FETCH_REGISTRATION,
  FETCH_REGISTRATIONS,
} from "../actions/actionTypes";

const initialState = {
  registration: null,
  registrations: [],
  filteredRegistrations: [],
  registrationsLoading: true,
};

export const registrationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REGISTRATION:
      return {
        ...state,
        registration: action.payload,
        registrationLoading: false,
      };

    case FETCH_REGISTRATIONS:
      return {
        ...state,
        registrations: action.payload[1],
        filteredRegistrations: action.payload[0],
        registrationsLoading: false,
      };

    default:
      return state;
  }
};

export default registrationsReducer;
