import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const initialState = {
  privatePricingInfo: [],
};

const programDetailsSlice = createSlice({
  name: "program-details",
  initialState,
  reducers: {
    setPrivatePricingInfo: (state, action: PayloadAction<any>) => {
      state.privatePricingInfo = [
        ...state.privatePricingInfo,
        action.payload as never,
      ];
    },
    removePrivatePricingInfo: (state, action) => {
      state.privatePricingInfo = state.privatePricingInfo.filter(
        (p: any) => p.programID.toString() !== action.payload.toString()
      );
    },
  },
});

export const { removePrivatePricingInfo, setPrivatePricingInfo } =
  programDetailsSlice.actions;

export default programDetailsSlice.reducer;
