export const linkWithLanguage = (link: string): string => {
  const pathName = window.location.pathname;
  const isAR = pathName.includes("/ar/");

  if (isAR) {
    // If the current language is Arabic and the link does not start with /ar/, prepend /ar/
    if (!link.startsWith("/ar/")) {
      return `/ar${link.startsWith("/") ? "" : "/"}${link}`;
    }
  } else {
    // If the current language is not Arabic, check if it is English
    if (!link.startsWith("/en/")) {
      // If the link does not start with /en/, prepend /en/
      return `/en${link.startsWith("/") ? "" : "/"}${link}`;
    }
  }
  // If none of the conditions apply, return the link as it is
  return link;
};

export const commonLinks = {
  homeLink: "/", // this is the processed link according to the activeLanguage
};
