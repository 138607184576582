import instance from "./instance";
import * as actionTypes from "./actionTypes";

// Actions
import { apiErrorCatcher } from "redux/slices/common-action-utils";
import { linkWithLanguage } from "components/helpers/commonLinks";

export const setParent = (parent) => ({
  type: actionTypes.SET_PARENT,
  payload: parent,
});

export const getParent = (userID) => async (dispatch) => {
  try {
    const res = await instance.get(`/parent/${userID}/`);
    const parent = res.data;

    await dispatch(setParent(parent));
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const ReturnParent = (userID) => async (dispatch) => {
  try {
    const res = await instance.get(`/parent/${userID}/`);
    const parent = res.data;

    return parent;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const updateParent =
  (userID, parentData, history) => async (dispatch) => {
    try {
      let formData = new FormData();
      Object.keys(parentData).forEach((key) => {
        key !== "user"
          ? formData.append(key, parentData[key])
          : formData.append(key, JSON.stringify(parentData[key]));
      });

      const res = await instance.put(`/parent/${userID}/`, formData);

      const response = res.data;
      const parent = response.parent.data;

      await dispatch(setParent(parent));

      if (history) {
        const link = linkWithLanguage(`/parent/${userID}/profile/`);
        history.push(link);
      }
    } catch (error) {
      apiErrorCatcher(error, dispatch);
      return "error";
    }
  };

export const removeChild = (userID, civilID) => async (dispatch) => {
  try {
    await instance.patch(`/parent/${userID}/link/`, { civil_id: civilID });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
