import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { apolloClient } from "apolloSettings";
import {
  GET_PUBLIC_PROGRAMS,
  GET_TRAINERS,
  MUTATION_TRAINER,
  DELETE_TRAINER,
  GET_PROGRAM_LIMITED,
} from "redux/actions/graphqlQueries/program";
import { apiErrorCatcher } from "./common-action-utils";
import { convertArraysToStrings } from "components/helpers/helper_functions";

const initialState = {
  loading: false,
  deleteLoader: false,
  formLoading: false,
  programsData: null,
  error: null,
  filters: null,
  search: null,
  trainersData: null,
  activeTrainersData: null,
  currentTrainer: null,
  program: null,
};

// API graphql endpoints
/**
 * One issue that has been observed is that the data is not refetched on year change,
 * need to fix
 */
export const getPublicPrograms = createAsyncThunk(
  "programs/getPublicPrograms",
  async (variables?: {}, thunkAPI?: any) => {
    const { filters } = thunkAPI.getState().rootProgramSlice;

    const newFilters = convertArraysToStrings(filters);

    try {
      const response = await apolloClient.query({
        query: GET_PUBLIC_PROGRAMS,
        variables:
          {
            first: 12,
            ...newFilters,
            ...variables,
          } || {},
        fetchPolicy: "no-cache", // Add this line to bypass the cache
      });
      return response.data.programs;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getActiveTrainers = createAsyncThunk(
  "programs/getActiveTrainers",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.query({
        query: GET_TRAINERS,
        variables:
          {
            isActive: true,
            ...variables,
          } || {},
        fetchPolicy: "no-cache", // Add this line to bypass the cache
      });
      return response.data.trainers;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getTrainers = createAsyncThunk(
  "programs/getTrainers",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.query({
        query: GET_TRAINERS,
        variables:
          {
            ...variables,
          } || {},
        fetchPolicy: "no-cache", // Add this line to bypass the cache
      });
      return response.data.trainers;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createUpdateTrainer = createAsyncThunk(
  "programs/createUpdateTrainer",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.mutate({
        mutation: MUTATION_TRAINER,
        variables:
          {
            ...variables,
          } || {},
      });
      return response.data.createUpdateTrainer.obj;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteTrainer = createAsyncThunk(
  "programs/deleteTrainer",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.mutate({
        mutation: DELETE_TRAINER,
        variables:
          {
            ...variables,
          } || {},
      });
      return response.data.deleteTrainer.deleted;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getProgram = createAsyncThunk(
  "programs/getProgram",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.query({
        query: GET_PROGRAM_LIMITED,
        variables:
          {
            ...variables,
          } || {},
        fetchPolicy: "no-cache", // Add this line to bypass the cache
      });
      return response.data.program;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const programSlice = createSlice({
  name: "programSlice",
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<any>) {
      state.filters = action.payload;
    },
    setSearch(state, action: PayloadAction<any>) {
      state.search = action.payload;
    },
    setProgramsData(state, action: PayloadAction<any>) {
      state.programsData = action.payload;
    },
    setCurrentTrainer(state, action: PayloadAction<any>) {
      state.currentTrainer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle the getPublicProgram async thunk
      .addCase(getPublicPrograms.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getPublicPrograms.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.programsData = action.payload;
        }
      )
      .addCase(
        getPublicPrograms.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      // get trainers
      .addCase(getActiveTrainers.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getActiveTrainers.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.activeTrainersData = action.payload;
        }
      )
      .addCase(
        getActiveTrainers.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(getTrainers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTrainers.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.trainersData = action.payload;
      })
      .addCase(getTrainers.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createUpdateTrainer.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        createUpdateTrainer.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.currentTrainer = action.payload;
        }
      )
      .addCase(
        createUpdateTrainer.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
        }
      )
      // Delete trainer
      .addCase(deleteTrainer.pending, (state) => {
        state.deleteLoader = true;
      })
      .addCase(deleteTrainer.fulfilled, (state, action: PayloadAction<any>) => {
        state.deleteLoader = false;
      })
      .addCase(deleteTrainer.rejected, (state, action: PayloadAction<any>) => {
        state.deleteLoader = false;
        state.error = action.payload;
      })
      // get program
      .addCase(getProgram.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProgram.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.program = action.payload;
      })
      .addCase(getProgram.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// // Action creators are generated for each case reducer function
export const { setFilters, setSearch, setProgramsData, setCurrentTrainer } =
  programSlice.actions;

export default programSlice.reducer;
