import axios from "redux/actions/instance";

export const createDiscountAPI = (payload: any) =>
  axios.post(`/programs/discounts/`, payload);

export const getDiscountsAPI = (query?: any) =>
  axios.get(`/programs/discounts/${query}`);

export const updateDiscountAPI = (discountId: number, payload: any) =>
  axios.put(`/programs/discounts/${discountId}/`, payload);

export const getDiscountDetailAPI = (discountId?: number) =>
  axios.get(`/programs/discounts/${discountId}/`);

export const deleteDiscountAPI = (discountId?: number) =>
  axios.delete(`/programs/discounts/${discountId}/`);
