import React from "react";
import { Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  title?: string;
  titleFullComponent?: React.ReactElement; // used to override the whole title/subtitle section with custom component
  subTitle?: string;
  topTitleComponent?: React.ReactElement;
  rightSideTitle?: string;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

const DetailDialogTitle = (props: Props) => {
  const { titleFullComponent, title, subTitle, onClose, topTitleComponent } =
    props;

  return (
    <>
      {onClose && (
        <IconButton
          onClick={onClose}
          sx={{
            borderRadius: 0,
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      )}
      {/*  Dialog Headers and close icon */}
      <Grid id="scroll-dialog-title" container spacing={3.75}>
        <Grid item xs={12}>
          {titleFullComponent ? (
            titleFullComponent
          ) : (
            <>
              {topTitleComponent ? topTitleComponent : null}
              {title ? (
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "1.428rem",
                    fontWeight: 700,
                    marginBottom: 0,
                    textTransform: "title",
                  }}
                  color={"primary"}
                >
                  {title}
                </Typography>
              ) : null}
              {subTitle ? (
                <Typography variant="body1">{subTitle}</Typography>
              ) : null}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DetailDialogTitle;
