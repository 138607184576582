import * as actionTypes from "./actionTypes";
import { handleError, resetErrors } from "./errors";
import instance from "./instance";

export const FetchUsher = (usherID, dataType) => async (dispatch) => {
  try {
    const response = await instance.get(
      `ushers/${usherID}/?dataType=${dataType}`
    );
    dispatch({
      type: actionTypes.SET_USHER_OBJ,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const EditUsherProfile = (usherID, data) => async () => {
  try {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    await instance.put(`ushers/${usherID}/`, formData);
    return true;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
    return false;
  }
};

export const EditUsherStatus = (usherID, data) => async () => {
  try {
    await instance.put(`ushers/${usherID}/status-update/`, data);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchUsherHistory = (usherID) => async () => {
  try {
    const response = await instance.get(`ushers/${usherID}/history/`);
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchInitiativeUsherDetails =
  (usherID, initiativeID) => async () => {
    try {
      const response = await instance.get(
        `initiatives/${initiativeID}/ushers/${usherID}/`
      );
      return response.data;
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const InitiativeUsherApply = (usherID, initiativeID) => async () => {
  try {
    const response = await instance.post(
      `/initiatives/${initiativeID}/apply/`,
      {
        usher: usherID,
        initiative: initiativeID,
        status: "Pending",
      }
    );
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const EditInitiativeUsher =
  (usherID, initiativeID, data) => async () => {
    try {
      await instance.put(
        `initiatives/${initiativeID}/ushers/${usherID}/`,
        data
      );
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const FetchTagsList = () => async () => {
  try {
    const response = await instance.get(`/tags/`);
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchGuideStatusList = () => async () => {
  try {
    const response = await instance.get("/guide_status/");
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const NewGuideStatus = (data) => async (dispatch) => {
  try {
    await instance.post("/guide_status/add/", data);
    dispatch(resetErrors());
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const EditGuideStatus = (guideStatusID, data) => async () => {
  try {
    await instance.put(`/guide_status/${guideStatusID}/`, data);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const NewCommunicationRecord = (data) => async (dispatch) => {
  try {
    await instance.post("/communication-history/add/", data);
    dispatch(resetErrors());
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const FetchCommunicationHistoryRecordsList =
  (initiativeUsherID) => async () => {
    try {
      const response = await instance.get(
        `/communication-history/${initiativeUsherID}/list/`
      );
      return response.data;
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const EditCommunicationHistoryRecord =
  (recordID, recordData) => async () => {
    try {
      await instance.put(`/communication-history/${recordID}/`, recordData);
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const DeleteCommunicationHistoryRecord = (recordID) => async () => {
  try {
    await instance.delete(`/communication-history/${recordID}/`);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchAttendanceHistoryRecordsList =
  (initiativeUsherID) => async () => {
    try {
      const response = await instance.get(
        `/attendance-history/${initiativeUsherID}/list/`
      );
      return response.data;
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };
