// this file is just a middleware for all the wrapper and direction switcher
import React, { useEffect, useMemo } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ApolloProvider } from "@apollo/client";
import { CacheProvider } from "@emotion/react";
import i18n from "config/i18n";
import { useLocation } from "react-router-dom";

import App from "./App";
import theme, {
  cacheLtr,
} from "components/common/Materialui/themes/main/default";
import rtlTheme, {
  cacheRtl,
} from "components/common/Materialui/themes/main/rtlTheme";
import AlertComponent from "components/common/Materialui/Alert/AlertComponent";
import { apolloClient } from "apolloSettings";
import { useTranslation } from "react-i18next";

const WrapperApp = () => {
  // Get the current URL
  const location = useLocation();
  const { i18n: activeI18n } = useTranslation();

  useEffect(() => {
    const hasArPath = location.pathname.includes("/ar/");
    if (hasArPath) {
      i18n.changeLanguage("ar");
    } else {
      i18n.changeLanguage("en");
    }
  }, [location.pathname]);

  const activeLanguage = activeI18n.language;

  const activeTheme = useMemo(() => {
    if (activeLanguage === "ar") {
      return rtlTheme;
    } else {
      return theme;
    }
  }, [activeLanguage]);

  const activeCache = useMemo(() => {
    if (activeLanguage === "ar") {
      return cacheRtl;
    } else {
      return cacheLtr;
    }
  }, [activeLanguage]);

  return (
    <CacheProvider value={activeCache}>
      <ThemeProvider theme={activeTheme}>
        <CssBaseline />
        <AlertComponent />
        <ApolloProvider client={apolloClient}>
          <App />
        </ApolloProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default WrapperApp;
