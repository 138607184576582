import { linkWithLanguage } from "components/helpers/commonLinks";
import { Redirect, Route } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/reducers/hooks";
import { setPrevLocation } from "redux/slices/locationSlice";

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ rootAuth }) => rootAuth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          return <Component {...props} />;
        } else {
          dispatch(setPrevLocation(props.location));
          return (
            <Redirect
              // Note this goes to /home to select the correct type of user
              to={{
                // @ts-ignore
                state: { ...props.location.state, from: props.location },
                pathname: linkWithLanguage("/accounts"),
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
