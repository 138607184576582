import instance from "./instance";

export const GenerateGeneralReport = (programID) => async (dispatch) => {
  try {
    const response = await instance.get(
      `/reports/${programID}/general-report/`
    );
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
