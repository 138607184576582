import { MenuItem } from "@mui/material";
import { linkWithLanguage } from "components/helpers/commonLinks";
import { navbarItemStyle } from "components/styles";
import { Link } from "react-router-dom";
import { NavbarOptionsNode } from "../options/IOption";

function NavbarMenuItem({
  child,
  handleTopCloseFunc,
}: {
  child: NavbarOptionsNode;
  handleTopCloseFunc: any;
}) {
  return (
    <div>
      <Link
        to={linkWithLanguage((child.to as string) ?? "")}
        onClick={() => handleTopCloseFunc()}
      >
        <MenuItem component="ul" sx={navbarItemStyle}>
          {child.title}
        </MenuItem>
      </Link>
    </div>
  );
}
export default NavbarMenuItem;
