import { ConfigProvider } from "antd";
import "antd/dist/antd.variable.min.css";

import ReactGA from "react-ga4";

import MainRoute from "routes";

import Footer from "components/footer/Footer";
import { linkWithLanguage } from "components/helpers/commonLinks";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { logoutButton } from "redux/slices/common-action-utils";
import Navbar from "./components/navigation/navbar";
import { persistor } from "./redux";

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTIC_ID; // OUR_TRACKING_ID

if (TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
}

ConfigProvider.config({
  theme: {
    primaryColor: "#00a84f",
  },
});

const App = () => {
  useEffect(() => {
    const handleStorageChange = (event) => {
      // Check for the logout event
      if (event.key === "logout") {
        persistor.purge();
        window.location.href = linkWithLanguage("/");
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    /**
     * This app version is used to mantain sync for the login sessions.
     * Primarily this is used when we have some changes in the login flow api.
     */
    const appVersion = "1.0.0";
    const storedVersion = Cookies.get("app_version");
    if (storedVersion && storedVersion !== appVersion) {
      // commented for now, implement afterwards
      // logoutButton("/accounts/?logout=true");
    } else {
      // Set the current version in cookies
      Cookies.set("app_version", appVersion, { expires: 365 });
    }
  }, []);

  return (
    <div className="app">
      <div className="main-content">
        <Navbar />
        <MainRoute />
      </div>
      <Footer />
    </div>
  );
};

export default App;
