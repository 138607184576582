import * as actionTypes from "./actionTypes";
import { handleError, resetErrors } from "./errors";
import instance from "./instance";

export const setCommnunityCenter = (communitycenter) => ({
  type: actionTypes.SET_COMMUNITY_CENTER,
  payload: communitycenter,
});

export const getCommunityCenter = (userID) => async (dispatch) => {
  try {
    const res = await instance.get(`/community_centers/${userID}/`);
    const communitycenter = res.data;

    dispatch(setCommnunityCenter(communitycenter));
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
export const updateCommunityCenter =
  (userID, communitycenterData, history) => async (dispatch) => {
    try {
      const formData = new FormData();
      Object.keys(communitycenterData).forEach((key) => {
        key !== "address" && key !== "user"
          ? formData.append(key, communitycenterData[key])
          : formData.append(key, JSON.stringify(communitycenterData[key]));
      });
      const res = await instance.put(`/community_centers/${userID}/`, formData);

      const response = res.data;
      const communityCenter = response.communitycenter.data;

      dispatch(setCommnunityCenter(communityCenter));
      dispatch(resetErrors());
      if (history) history.push(`/communitycenter/${userID}/profile/`);
    } catch (error) {
      dispatch(handleError(error));
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };
export const NewVolunteerRequest =
  (requestData, userID, history) => async (dispatch) => {
    try {
      await instance.post("/volunteer_requests/", {
        ...requestData,
        comm_user_id: userID,
      });
      await dispatch(getCommunityCenter(userID));
      dispatch(resetErrors());
      if (history) history.push("/volunteer_requests/");
    } catch (error) {
      await dispatch(handleError(error));
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };
export const addCommunityCenterInvitee = (data) => async (dispatch) => {
  try {
    await instance.post(`communitycenter/invitee/add/`, data);
    dispatch(resetErrors());
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const addCommunityCenterInvitation =
  (inviteesList, program) => async (dispatch) => {
    try {
      const data = inviteesList.map((obj) => {
        return { program, invitee: obj.id, email: obj.email, cc: obj.cc };
      });
      await instance.post(`community_center/invitation/add/`, data);
      dispatch(resetErrors());
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const updateCenterInvitee = (data) => async (dispatch) => {
  try {
    await instance.put(`invitee/${data.id}/updateCenter/`, data);
    dispatch(resetErrors());
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const resendCenterInvitation = (data) => async (dispatch) => {
  try {
    await instance.put(`invitation/${data.id}/resend-center-invitation/`);
    dispatch(resetErrors());
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const fetchCommunitycenters = () => async (dispatch) => {
  try {
    const res = await instance.get(`/community_centers/`);
    const communitycenters = res.data;

    dispatch({
      type: actionTypes.FETCH_COMMUNITY_CENTERS,
      payload: communitycenters,
    });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
export const FetchCommunityCentersList = () => async (dispatch) => {
  try {
    const res = await instance.get(`/community_centers/`);
    const centers = res.data;

    dispatch({ type: actionTypes.FETCH_COMMUNITY_CENTERS, payload: centers });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
export const FetchParticipatedCenters = (registrationID) => async () => {
  try {
    const res = await instance.get(`/community_centers/`);
    return res.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchProgramParticipatedCenters = (programID) => async () => {
  try {
    const res = await instance.get(`/community_centers/program/${programID}/`);
    return res.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const updateCenterInvitation = (data) => async (dispatch) => {
  try {
    await instance.put(`centerinvitation/${data.id}/update/`, data);
    dispatch(resetErrors());
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateCenterRequest = (data, history) => async (dispatch) => {
  try {
    const response = await instance.put(`volunteer_requests/new/${data.id}/`, {
      ...data,
    });
    dispatch(resetErrors());

    if (history) history.push(`/volunteer_requests/`);
    return response.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const fetchAssignedShiftDetails = (shiftID) => async (dispatch) => {
  try {
    const res = await instance.get(`/shifts/${shiftID}/details/`);
    const registration = res.data;
    return registration;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
export const fetchVolunteersShiftsRequest =
  (program_id, month) => async (dispatch) => {
    try {
      const shiftsData = await instance.get(
        `communitycentersList/shifts/${program_id}/${month}/`
      );
      return shiftsData.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const fetchVolunteersShiftsRequestStaff =
  (program_id, month) => async (dispatch) => {
    try {
      const shiftsData = await instance.get(
        `community_centers/shifts/staff/${program_id}/${month}/`
      );
      return shiftsData.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const NewCommunityCenter = (data) => async (dispatch) => {
  try {
    const response = await instance.post("new/community-center/", data);
    return response.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};
