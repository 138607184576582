import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createDiscountAPI,
  updateDiscountAPI,
  deleteDiscountAPI,
  getDiscountsAPI,
} from "redux/services/discounts";
import { createQuery } from "./common-action-utils";
import { apiErrorCatcher } from "./common-action-utils";

const initialState = {
  currentDiscount: null,
  programListDiscount: [],
  loading: false,
  error: null,
  currentFees: null,
  activeDiscountList: null,
};

// API calls/methods

// Create an async thunk for making the API call
export const createDiscount = createAsyncThunk(
  "api/createDiscount",
  async (payload: any, thunkAPI) => {
    try {
      const response = await createDiscountAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateDiscount = createAsyncThunk(
  "api/updateDiscount",
  async (payload: any, thunkAPI) => {
    const { id, data } = payload;

    try {
      const response = await updateDiscountAPI(id, data);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteDiscount = createAsyncThunk(
  "api/deleteDiscount",
  async (payload: any, thunkAPI) => {
    const { id } = payload;

    try {
      const response = await deleteDiscountAPI(id);
      return { data: response.data, id: id };
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getDiscountByProgram = createAsyncThunk(
  "api/getDiscountByProgram",
  async (payload: { program: number }, thunkAPI) => {
    const query = "?" + createQuery({ filter: payload });
    try {
      const response = await getDiscountsAPI(query);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getActiveDiscounts = createAsyncThunk(
  "api/getActiveDiscounts",
  async (payload: { program_exists: boolean }, thunkAPI) => {
    const query = "?" + createQuery({ filter: payload });
    try {
      const response = await getDiscountsAPI(query);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const discountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    setCurrentDiscount(state, action: PayloadAction<any>) {
      state.currentDiscount = action.payload;
    },
    setCurrentProgramList(state, action: PayloadAction<any>) {
      state.programListDiscount = action.payload;
    },
    setCurrentFees(state, action: PayloadAction<any>) {
      state.currentFees = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle the createDiscount async thunk
      .addCase(createDiscount.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createDiscount.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          //@ts-ignore
          state.programListDiscount.push(action.payload);
          state.error = null;
        }
      )
      .addCase(createDiscount.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle the updatedDiscount async thunk
      .addCase(updateDiscount.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateDiscount.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          // Check if the updated item's ID already exists in the programListDiscount array
          const updatedDiscountIndex = state.programListDiscount.findIndex(
            (item: any) => item.id === action.payload.id
          );

          if (updatedDiscountIndex !== -1) {
            // If the ID exists, update the existing item
            //@ts-ignore
            state.programListDiscount[updatedDiscountIndex] = action.payload;
          } else {
            // If the ID does not exist, add the new item to the array
            //@ts-ignore
            state.programListDiscount.push(action.payload);
          }
          state.error = null;
        }
      )
      .addCase(updateDiscount.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle the deleteDiscount async thunk
      .addCase(deleteDiscount.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        deleteDiscount.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          // Check if the updated item's ID already exists in the programListDiscount array
          const deletedDiscountIndex = state.programListDiscount.findIndex(
            (item: any) => item.id === action.payload.id
          );

          if (deletedDiscountIndex !== -1) {
            // If the ID exists, delete item
            //@ts-ignore
            state.programListDiscount.splice(deletedDiscountIndex, 1);
          }
          state.error = null;
        }
      )
      .addCase(deleteDiscount.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle the getDiscounts by program id async thunk
      .addCase(getDiscountByProgram.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getDiscountByProgram.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.programListDiscount = action.payload;
          state.error = null;
        }
      )
      .addCase(
        getDiscountByProgram.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      // Handle the getActiveDiscounts async thunk
      .addCase(getActiveDiscounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getActiveDiscounts.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.activeDiscountList = action.payload;
          state.error = null;
        }
      )
      .addCase(
        getActiveDiscounts.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentDiscount, setCurrentProgramList, setCurrentFees } =
  discountSlice.actions;

export default discountSlice.reducer;
