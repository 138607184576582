import rootReducer from "./reducers";
import discountSlice from "./slices/discountSlice";
import alertSlice from "./slices/alertSlice";
import registrationSlice from "./slices/registrationSlice";
import coreProgramSlice from "./slices/coreProgramSlice";
import applicantSlice from "./slices/applicantSlice";
import programSlice from "./slices/programSlice";
import productSlice from "./slices/productSlice";
import inquiriesSlice from "./slices/inquiriesSlice";
import newAuthSlice from "./slices/newAuthSlice";
import navbarSlice from "components/navigation/navbar/slice";
import locationSlice from "./slices/locationSlice";
import authReducer from "./reducers/authentication";
// import { checkForExpiredToken } from "./actions";
import { configureStore } from "@reduxjs/toolkit"; // Import configureStore from Redux Toolkit
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage
import programDetailsSlice from "components/programs/programDetails/programDetailsSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "user",
    "applicant",
    "company",
    "loyacStaff",
    "parent",
    "supervisor",
    "communitycenter",
  ], // Only persist the user slice
};

const persistedReducer = persistReducer(persistConfig, authReducer);

// Use the existing thunk middleware with Redux Toolkit's configureStore
const store = configureStore({
  reducer: {
    ...rootReducer,
    rootDiscounts: discountSlice,
    rootCorePrograms: coreProgramSlice,
    alert: alertSlice,
    programDetails: programDetailsSlice,
    rootRegistrationSlice: registrationSlice,
    rootApplicantSlice: applicantSlice,
    rootProgramSlice: programSlice,
    rootProductSlice: productSlice,
    rootInquiriesSlice: inquiriesSlice,
    navbar: navbarSlice,
    rootLocationSlice: locationSlice,
    rootNewAuthSlice: newAuthSlice,
    rootAuth: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// store.dispatch(checkForExpiredToken());

// Infer the `RootState` and `AppDispatch` types from the store itself
//@ts-ignore
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
