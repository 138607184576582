import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CommonNavigation from "../CommonNavigation";
import { NavbarOptionsNode, commonNavigationLinks } from "./IOption";

function ParentOptions() {
  const { user } = useSelector((state: any) => state.rootAuth);
  const { t } = useTranslation();

  const data: NavbarOptionsNode[] = [
    commonNavigationLinks(t).programs,
    {
      title: t("common.children"),
      to: `/parent/${user.user_id}/children/`,
    },
  ];
  return <CommonNavigation data={data} />;
}

export default ParentOptions;
