import { gql } from "@apollo/client";

export const GET_ORDERS = gql`
  query GetOrders($number: String) {
    orders(number: $number) {
      edges {
        node {
          number
          customerName
          phoneNumber
          guestEmail
          status
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query {
    products(browsable: true) {
      edgeCount
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          pk
          id
          titleEn
          titleAr
          descriptionAr
          descriptionEn
          price
          originalPrice
          structure
          primaryImage
          images {
            edges {
              node {
                original
              }
            }
          }
          children(isPublic: true) {
            edges {
              node {
                pk
                id
                childTitleEn
                childTitleAr
              }
            }
          }
        }
      }
    }
  }
`;

export const ADD_TO_CART_MUTATION = gql`
  mutation AddToCart($basket: ID, $product: ID!, $quantity: Int!) {
    addToCart(
      input: { basket: $basket, product: $product, quantity: $quantity }
    ) {
      basket {
        pk
        id
        total
        status
        customerName
        phoneNumber
        guestEmail
        lines {
          edges {
            node {
              id
              lineReference
              quantity
              price
              unitPrice
              product {
                primaryImage
                titleEn
                titleAr
              }
            }
          }
        }
      }
      errors {
        messages
      }
    }
  }
`;

export const GET_BASKET = gql`
  query GetBasket($basketId: ID!) {
    basket(id: $basketId) {
      pk
      id
      total
      status
      customerName
      phoneNumber
      guestEmail
      lines {
        edges {
          node {
            id
            lineReference
            quantity
            price
            unitPrice
            product {
              primaryImage
              titleEn
              titleAr
            }
          }
        }
      }
    }
  }
`;

export const CHECKOUT_MUTATION = gql`
  mutation CheckoutView(
    $basket: ID!
    $customerName: String!
    $phoneNumber: String!
    $guestEmail: String!
    $retry: Boolean
  ) {
    checkoutView(
      input: {
        basket: $basket
        customerName: $customerName
        phoneNumber: $phoneNumber
        guestEmail: $guestEmail
        retry: $retry
      }
    ) {
      paymentUrl
      order {
        phoneNumber
        number
        customerName
        id
        total
      }
      errors {
        messages
      }
    }
  }
`;

export const UPDATE_LINE_QUANTITY = gql`
  mutation UpdateLineQuantity($id: ID!, $quantity: Int!) {
    updateLineQuantity(input: { id: $id, quantity: $quantity }) {
      basket {
        pk
        id
        total
        status
        customerName
        phoneNumber
        guestEmail
        lines {
          edges {
            node {
              id
              lineReference
              quantity
              price
              unitPrice
              product {
                primaryImage
                titleEn
                titleAr
              }
            }
          }
        }
      }
      errors {
        messages
      }
    }
  }
`;

export const DELETE_LINE = gql`
  mutation DeleteLine($id: ID!) {
    deleteBasketLine(id: $id) {
      basket {
        pk
        id
        total
        status
        customerName
        phoneNumber
        guestEmail
        lines {
          edges {
            node {
              id
              lineReference
              quantity
              price
              unitPrice
              product {
                primaryImage
                titleEn
                titleAr
              }
            }
          }
        }
      }
    }
  }
`;

export const REOPEN_CART = gql`
  mutation ReOpenCart($id: ID!) {
    cleanBasket(id: $id) {
      basket {
        pk
        id
        total
        status
        customerName
        phoneNumber
        guestEmail
        lines {
          edges {
            node {
              id
              lineReference
              quantity
              price
              unitPrice
              product {
                primaryImage
                titleEn
                titleAr
              }
            }
          }
        }
      }
    }
  }
`;
