import instance from "./instance";

export const surveyResponse =
  (programID, surveyID, userID, responseData, BackToProgram, childId) =>
  async () => {
    let targetUrl = `/programs/${programID}/surveys/${surveyID}/`;
    if (childId) {
      targetUrl = `${targetUrl}?child_id=${childId}`;
    }

    try {
      await instance.post(targetUrl, {
        response_data: responseData,
        user_id: userID,
      });
      if (BackToProgram) {
        window.location.href = `/programs/${programID}/`;
      } else if (childId) {
        window.location.href = `/applicant/${childId}/history/`;
      } else {
        window.location.href = `/applicant/${userID}/history/`;
      }
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const surveyResponseV2 =
  (programID, surveyID, userID, responseData, childId) => async () => {
    try {
      const queryString = childId ? `?child_id=${childId}` : "";

      const response = await instance.post(
        `/programs/${programID}/surveys/${surveyID}/${queryString}`,
        {
          response_data: responseData,
          user_id: userID,
        }
      );

      return response;
    } catch (error) {
      if (error.response) {
        return error.response; // Return the error response
      } else {
        throw error; // Rethrow the error if it's not an HTTP response error
      }
    }
  };

export const FetchProgramSurvey = (programID, type) => async () => {
  try {
    const response = await instance.get(
      `/program/${programID}/simplified/survey/?type=${type}`
    );
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchProgramSurveyResponse = (programID, surveyID) => async () => {
  try {
    const response = await instance.get(
      `/program/${programID}/simplified/survey/${surveyID}/response/`
    );
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
