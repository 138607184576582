import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  patchRegistrationAPI,
  bulkDarbiUpdateAPI,
  parentRegisterChildrenAPI,
  parentBasketAPI,
  parentRegistrationCheckoutAPI,
  parentApplyCouponAPI,
  parentRemoveCouponAPI,
} from "redux/services/registrations";
import { apiErrorCatcher } from "./common-action-utils";
import {
  GET_COMPANY_ACCESS_PROFILE,
  GET_RELATED_REGISTRATION,
} from "redux/actions/graphqlQueries/registration";
import { apolloClient } from "apolloSettings";
import { createQuery } from "./common-action-utils";
import { GET_PAYMENT_REGISTRATION } from "redux/actions/graphqlQueries/registration";
import { convertApolloNodeToFlatArray } from "components/helpers/helper_functions";

const initialState = {
  loading: false,
  formLoading: false,
  jobSelectionLoading: false,
  error: null,
  formSuccess: false,
  companyCanAccess: false,
  parentBasketData: null,
  paymentRegistration: null,
  relatedRegistration: null,
  addCouponLoading: false,
};

// API calls/methods
export const getCompanyAccess = createAsyncThunk(
  "registrations/getCompanyAccess",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.query({
        query: GET_COMPANY_ACCESS_PROFILE,
        variables:
          {
            ...variables,
          } || {},
      });
      return response;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const patchRegistration = createAsyncThunk(
  "api/patchRegistration",
  async (payload: any, thunkAPI) => {
    const { id, data, rawQuery } = payload;
    const query = "?" + createQuery({ filter: rawQuery || {} });

    try {
      const response = await patchRegistrationAPI(id, data, query);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const bulkDarbiUpdate = createAsyncThunk(
  "api/bulkDarbiUpdate",
  async (payload: any, thunkAPI) => {
    try {
      const response = await bulkDarbiUpdateAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const parentRegisterChildren = createAsyncThunk(
  "registration/parentRegisterChildren",
  async (payload: any, thunkAPI) => {
    try {
      const response = await parentRegisterChildrenAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const parentBasket = createAsyncThunk(
  "registration/parentBasket",
  async (ids: any, thunkAPI) => {
    try {
      const response = await parentBasketAPI(ids);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const parentCheckout = createAsyncThunk(
  "registration/parentCheckout",
  async (payload: any, thunkAPI) => {
    try {
      const response = await parentRegistrationCheckoutAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getPaymentRegistration = createAsyncThunk(
  "registrations/getPaymentRegistration",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.query({
        query: GET_PAYMENT_REGISTRATION,
        variables:
          {
            ...variables,
          } || {},
        fetchPolicy: "no-cache", // Add this line to bypass the cache
      });
      return response;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getRelatedRegistrations = createAsyncThunk(
  "registrations/getRelatedRegistrations",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.query({
        query: GET_RELATED_REGISTRATION,
        variables:
          {
            ...variables,
          } || {},
        fetchPolicy: "no-cache", // Add this line to bypass the cache
      });
      return response;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const parentApplyCoupon = createAsyncThunk(
  "registration/parentApplyCoupon",
  async (payload: any, thunkAPI) => {
    try {
      const response = await parentApplyCouponAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const parentRemoveCoupon = createAsyncThunk(
  "registration/parentRemoveCoupon",
  async (payload: any, thunkAPI) => {
    try {
      const response = await parentRemoveCouponAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const registrationSlice = createSlice({
  name: "registrationSlice",
  initialState,
  reducers: {
    setJobSelectionLoader(state, action: PayloadAction<any>) {
      state.jobSelectionLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // Handle the patchRegistration async thunk
      .addCase(patchRegistration.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        patchRegistration.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(
        patchRegistration.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
          state.formSuccess = false;
        }
      )

      // bulk darbi update
      .addCase(bulkDarbiUpdate.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        bulkDarbiUpdate.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(
        bulkDarbiUpdate.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
          state.formSuccess = false;
        }
      )

      //parent children registrations
      .addCase(parentRegisterChildren.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        parentRegisterChildren.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(
        parentRegisterChildren.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
          state.formSuccess = false;
        }
      )

      .addCase(parentBasket.pending, (state) => {
        state.loading = true;
      })
      .addCase(parentBasket.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.parentBasketData = action.payload.data;
      })
      .addCase(parentBasket.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getCompanyAccess.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCompanyAccess.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.companyCanAccess =
            action.payload.data.isCompanyAllowedApplicantProfile;
        }
      )
      .addCase(
        getCompanyAccess.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
          state.loading = false;
        }
      )

      // parent checkout
      .addCase(parentCheckout.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        parentCheckout.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(parentCheckout.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
        state.formSuccess = false;
      })
      // payment registration
      .addCase(getPaymentRegistration.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getPaymentRegistration.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.paymentRegistration = action.payload.data.registration;
        }
      )
      .addCase(
        getPaymentRegistration.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.loading = false;
        }
      )

      .addCase(getRelatedRegistrations.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getRelatedRegistrations.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = null;
          state.relatedRegistration = convertApolloNodeToFlatArray(
            action?.payload?.data?.registrations?.edges
          );
        }
      )
      .addCase(
        getRelatedRegistrations.rejected,
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.loading = false;
        }
      )
      // parent coupons
      .addCase(parentApplyCoupon.pending, (state) => {
        state.addCouponLoading = true;
      })
      .addCase(
        parentApplyCoupon.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.addCouponLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(
        parentApplyCoupon.rejected,
        (state, action: PayloadAction<any>) => {
          state.addCouponLoading = false;
          state.error = action.payload;
          state.formSuccess = false;
        }
      )
      .addCase(parentRemoveCoupon.pending, (state) => {
        state.addCouponLoading = true;
      })
      .addCase(
        parentRemoveCoupon.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.addCouponLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(
        parentRemoveCoupon.rejected,
        (state, action: PayloadAction<any>) => {
          state.addCouponLoading = false;
          state.error = action.payload;
          state.formSuccess = false;
        }
      );
  },
});

// // Action creators are generated for each case reducer function
export const { setJobSelectionLoader } = registrationSlice.actions;

export default registrationSlice.reducer;
