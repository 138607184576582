/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchThreads } from "redux/actions";
import CommonNavigation from "../CommonNavigation";
import useNavigation from "../hooks/useNavigation";
import "../styles.css";
function LoyacStaffOptions() {
  const dispatch = useDispatch();
  const [threadsListLoading, setThreadsListLoading] = useState(true);
  const threadsList = useSelector((state: any) => state.rootThreads.threads);
  const user = useSelector((state: any) => state.rootAuth.user);

  const fetchThreadsData = async () => await dispatch(FetchThreads());

  useEffect(() => {
    // TODO: fix this later due to its fetching all from DB which kill the FE
    // fetchThreadsData();
    setThreadsListLoading(false);
  }, [dispatch, threadsListLoading]);

  useEffect(() => {
    const saveThreadsToLocalStorage = () => {
      if (window.location.href.includes("not/responded/")) {
        return;
      }

      const newThreadsCount = threadsList.filter(
        (thread: { messages: string[] | any }) =>
          !thread.messages[thread.messages.length - 1]?.responded_to
      ).length;

      const oldThreadsCount = parseInt(
        localStorage.getItem("old_threads") || "0",
        10
      );

      if (oldThreadsCount === newThreadsCount) {
        localStorage.setItem("threads", "0");
      } else {
        localStorage.setItem(
          "threads",
          Math.max(newThreadsCount - oldThreadsCount, 0).toString()
        );
      }
    };
    saveThreadsToLocalStorage();
  }, [threadsListLoading]);

  const { loyacStaff, tempEvents } = useNavigation();
  return (
    <CommonNavigation
      data={user.email === "ushervolunteer@loyac.org" ? tempEvents : loyacStaff}
    />
  );
}

export default LoyacStaffOptions;
