// Actions
import { FETCH_THREAD, FETCH_THREADS } from "../actions/actionTypes";

const initialState = {
  threads: [],
  thread: "",
  threadLoading: true,
  threadsLoading: true,
};

export const threadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_THREADS:
      return {
        ...state,
        threads: action.payload,
        threadsLoading: false,
      };
    case FETCH_THREAD:
      return {
        ...state,
        thread: action.payload,
        threadLoading: false,
      };
    default:
      return state;
  }
};

export default threadsReducer;
