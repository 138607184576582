import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  getCoreProgramsAPI,
  createCoreProgramAPI,
  updateCoreProgramAPI,
  approvedCoreProgramAPI,
} from "redux/services/corePrograms";
import { createQuery } from "./common-action-utils";
import { apiErrorCatcher } from "./common-action-utils";

const initialState = {
  corePrograms: null,
  loading: false,
  formLoading: false,
  error: null,
  filters: null,
  currentCoreProgram: null,
  selectedCoreProgram: null,
};

// API calls/methods

// Create an async thunk for making the API call
export const createCoreProgram = createAsyncThunk(
  "api/createCoreProgram",
  async (payload: any, thunkAPI) => {
    try {
      const response = await createCoreProgramAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateCoreProgram = createAsyncThunk(
  "api/updateCoreProgram",
  async (payload: any, thunkAPI) => {
    const { id, data } = payload;

    try {
      const response = await updateCoreProgramAPI(id, data);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const approvedCoreProgram = createAsyncThunk(
  "api/approvedCoreProgram",
  async (payload: any, thunkAPI) => {
    const { id, data } = payload;

    try {
      const response = await approvedCoreProgramAPI(id, data);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getCorePrograms = createAsyncThunk(
  "api/getCorePrograms",
  async (payload?: {}, thunkAPI?: any) => {
    const { filters } = thunkAPI.getState().rootCorePrograms; // Access current state here
    const queryParams = { ...filters, ...payload };
    const query = "?" + createQuery({ filter: queryParams });
    try {
      const response = await getCoreProgramsAPI(query);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const coreProgramSlice = createSlice({
  name: "coreProgram",
  initialState,
  reducers: {
    applyFilters(state, action: PayloadAction<any>) {
      state.filters = action.payload;
    },
    setCurrentCoreProgram(state, action: PayloadAction<any>) {
      state.currentCoreProgram = action.payload;
    },
    setSelectedCoreProgram(state, action: PayloadAction<any>) {
      state.selectedCoreProgram = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // GET CORE PROGRAMS
      .addCase(getCorePrograms.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCorePrograms.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.corePrograms = action.payload;
          state.error = null;
        }
      )
      .addCase(
        getCorePrograms.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      // Handle the createCoreProgram async thunk
      .addCase(createCoreProgram.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        createCoreProgram.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
        }
      )
      .addCase(
        createCoreProgram.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
        }
      )
      // Handle the updateCreateCoreProgram async thunk
      .addCase(updateCoreProgram.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        updateCoreProgram.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
        }
      )
      .addCase(
        updateCoreProgram.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
        }
      )
      // Handle the approvedCoreProgram async thunk
      .addCase(approvedCoreProgram.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        approvedCoreProgram.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
        }
      )
      .addCase(
        approvedCoreProgram.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
        }
      );
  },
});

// Action creators are generated for each case reducer function
export const { applyFilters, setCurrentCoreProgram, setSelectedCoreProgram } =
  coreProgramSlice.actions;

export default coreProgramSlice.reducer;
