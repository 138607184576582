import { Button, Grid, Typography } from "@mui/material";
import CustomContainer from "components/common/Materialui/Container/CustomContainer";
import { muiGridContainerSpacing } from "components/common/js/constants";
import { commonLinks, linkWithLanguage } from "components/helpers/commonLinks";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const PermissionDenied = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <CustomContainer>
      <Grid
        container
        spacing={muiGridContainerSpacing}
        sx={{ height: "80vh" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h1">403 - Forbidden</Typography>
          <Typography variant="h4" mb={2}>
            Permission Denied
          </Typography>
          <Button
            onClick={() => history.push(linkWithLanguage(commonLinks.homeLink))}
            variant="contained"
            size="large"
          >
            {t("common.home")}
          </Button>
        </Grid>
      </Grid>
    </CustomContainer>
  );
};

export default PermissionDenied;
