// Actions
import {
  FETCH_ACTIVITIES_LIST,
  FETCH_ACTIVITIES,
  FETCH_ACTIVITY,
} from "../actions/actionTypes";

const initialState = {
  activity: "",
  activityLoading: true,
  activities: [],
  activitiesLoading: true,
  activitiesList: [],
  activitiesListLoading: true,
};

export const activitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVITY:
      return {
        ...state,
        activity: action.payload,
        activitiyLoading: false,
      };
    case FETCH_ACTIVITIES:
      return {
        ...state,
        activities: action.payload,
        activitiesLoading: false,
      };
    case FETCH_ACTIVITIES_LIST:
      return {
        ...state,
        activitiesList: action.payload,
        activitiesListLoading: false,
      };

    default:
      return state;
  }
};

export default activitiesReducer;
