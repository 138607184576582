import * as actionTypes from "./actionTypes";
import instance from "./instance";

// Actions
import { login } from "redux/slices/newAuthSlice";
import {
  GetSupervisor,
  getApplicantSimplified,
  getCommunityCenter,
  getCompany,
  getParent,
  getStaff,
  handleError,
  resetErrors,
} from "../actions";

export const setUser = (user) => async (dispatch) => {
  if (user.is_parent) await dispatch(getParent(user.user_id));
  else if (user.is_company) await dispatch(getCompany(user.user_id));
  else if (user.is_community_center)
    await dispatch(getCommunityCenter(user.user_id));
  else if (user.is_loyac_staff) await dispatch(getStaff(user.user_id));
  else if (user.is_applicant) {
    await dispatch(getApplicantSimplified(user.user_id));
  } else if (user.is_thirdParty_supervisor) {
    await dispatch(GetSupervisor(user.user_id));
  }
  await dispatch({
    type: actionTypes.SET_CURRENT_USER,
    payload: user,
  });
};

export const signup = (userData, history) => {
  return async (dispatch) => {
    try {
      const response = await instance.post("/signup/", userData);
      const { email, password } = userData;
      const res = await dispatch(login({ email, password }));

      if (userData.is_community_center) {
        dispatch(getCommunityCenter(response.data.id));
      }
      dispatch(resetErrors());
      return res;
    } catch (error) {
      dispatch(handleError(error));
      return "error";
    }
  };
};

export const ApplicantsSignup = (userData) => {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      Object.keys(userData).forEach((key) => {
        formData.append(key, userData[key]);
      });
      await instance.post("/applicants/signup/", formData);
      const { email, password } = userData;
      await dispatch(login({ email, password }));
      dispatch(resetErrors());
      return "success";
    } catch (error) {
      dispatch(handleError(error));
      return "error";
    }
  };
};
export const subscribeEmail = (email, status) => {
  return async (dispatch) => {
    try {
      const { data } = await instance.post(
        "v2/applicant/mailchimp/subscription/",
        {
          email,
          subscribing: status,
        }
      );
      dispatch(resetErrors());
      return data;
    } catch (error) {
      dispatch(handleError(error));
      return "error";
    }
  };
};

export const updatePassword = (userData) => {
  return async (dispatch) => {
    try {
      await instance.post("/password_reset/confirm/", userData);
      dispatch(resetErrors());
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};
