import React from "react";
import { Typography, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { commonLinks, linkWithLanguage } from "components/helpers/commonLinks";
import CustomContainer from "components/common/Materialui/Container/CustomContainer";
import { muiGridContainerSpacing } from "components/common/js/constants";

const NotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <CustomContainer>
      <Grid
        container
        spacing={muiGridContainerSpacing}
        sx={{ height: "80vh" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h1" data-testid="notfound-header-testid">
            404
          </Typography>
          <Typography variant="h4" mb={2} data-testid="notfound-text-testid">
            {t("common.notFound")}
          </Typography>
          <Button
            onClick={() => history.push(linkWithLanguage(commonLinks.homeLink))}
            variant="contained"
            size="large"
            data-testid="notfound-button-testid"
          >
            {t("common.home")}
          </Button>
        </Grid>
      </Grid>
    </CustomContainer>
  );
};

export default NotFound;
