import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { apolloClient } from "apolloSettings";
import {
  GET_PRODUCTS,
  ADD_TO_CART_MUTATION,
  GET_BASKET,
  CHECKOUT_MUTATION,
  UPDATE_LINE_QUANTITY,
  DELETE_LINE,
  REOPEN_CART,
  GET_ORDERS,
} from "redux/actions/graphqlQueries/product";
import { apiErrorCatcher } from "./common-action-utils";
import {
  setKeyValueStorage,
  deleteKeyStorage,
} from "components/helpers/helper_functions";

const initialState = {
  loading: false,
  formLoading: false,
  productData: null,
  error: null,
  activeProduct: null,
  basket: null,
  paymentUrl: null,
  orderData: null,
};

// API graphql endpoints

const setRemoveBasket = (basket: any) => {
  const { status } = basket || {};
  if (!basket || status === "SUBMITTED") {
    deleteKeyStorage("basket");
    return null;
  }

  // set basket id on storage
  setKeyValueStorage("basket", basket.id);

  return basket;
};

export const getProducts = createAsyncThunk(
  "product/getProducts",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.query({
        query: GET_PRODUCTS,
        variables: {},
        fetchPolicy: "no-cache", // Add this line to bypass the cache
      });
      return response.data.products;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addProductToCart = createAsyncThunk(
  "product/addProductToCart",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.mutate({
        mutation: ADD_TO_CART_MUTATION,
        variables:
          {
            ...variables,
          } || {},
      });
      return response.data.addToCart.basket;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getBasket = createAsyncThunk(
  "product/getBasket",
  async (variables: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.query({
        query: GET_BASKET,
        variables: variables,
        fetchPolicy: "no-cache", // Add this line to bypass the cache
      });
      return response.data.basket;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const checkoutView = createAsyncThunk(
  "product/checkoutView",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.mutate({
        mutation: CHECKOUT_MUTATION,
        variables:
          {
            ...variables,
          } || {},
      });
      return response.data.checkoutView.paymentUrl;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateLine = createAsyncThunk(
  "product/updateLine",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.mutate({
        mutation: UPDATE_LINE_QUANTITY,
        variables:
          {
            ...variables,
          } || {},
      });

      const basket = response.data.updateLineQuantity.basket;

      if (response.data.updateLineQuantity.errors?.length) {
        apiErrorCatcher(
          response.data.updateLineQuantity.errors,
          thunkAPI.dispatch
        );
        return thunkAPI.rejectWithValue("rejected");
        // If there is an error in the GraphQL response, throw it
      }

      return basket;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteLine = createAsyncThunk(
  "product/deleteLine",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.mutate({
        mutation: DELETE_LINE,
        variables:
          {
            ...variables,
          } || {},
      });
      return response.data.deleteBasketLine.basket;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const reOpenCart = createAsyncThunk(
  "product/reOpenCart",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.mutate({
        mutation: REOPEN_CART,
        variables:
          {
            ...variables,
          } || {},
      });
      return response.data.cleanBasket.basket;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getOrders = createAsyncThunk(
  "product/getOrders",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.query({
        query: GET_ORDERS,
        variables: {},
        fetchPolicy: "no-cache", // Add this line to bypass the cache
      });
      return response.data.orders;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const productSlice = createSlice({
  name: "productSlice",
  initialState,
  reducers: {
    setActiveProduct(state, action: PayloadAction<any>) {
      state.activeProduct = action.payload;
    },
    setBasket(state, action: PayloadAction<any>) {
      state.basket = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle the getPublicProgram async thunk
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.productData = action.payload;
      })
      .addCase(getProducts.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })

      // add to cart
      .addCase(addProductToCart.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        addProductToCart.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.basket = setRemoveBasket(action.payload);
        }
      )
      .addCase(
        addProductToCart.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
        }
      )
      // get basket
      .addCase(getBasket.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBasket.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.basket = setRemoveBasket(action.payload);
      })
      .addCase(getBasket.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // checkout
      .addCase(checkoutView.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(checkoutView.fulfilled, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.paymentUrl = action.payload;
      })
      .addCase(checkoutView.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
      })
      // update line
      .addCase(updateLine.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(updateLine.fulfilled, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.basket = setRemoveBasket(action.payload);
      })
      .addCase(updateLine.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
      })
      // delete line
      .addCase(deleteLine.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(deleteLine.fulfilled, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.basket = setRemoveBasket(action.payload);
      })
      .addCase(deleteLine.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
      })
      // reopen cart
      .addCase(reOpenCart.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(reOpenCart.fulfilled, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.basket = setRemoveBasket(action.payload);
      })
      .addCase(reOpenCart.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
      })
      // get orders
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrders.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.orderData = action.payload;
      })
      .addCase(getOrders.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// // // Action creators are generated for each case reducer function
export const { setActiveProduct, setBasket } = productSlice.actions;

export default productSlice.reducer;
