import { message } from "antd";
import moment from "moment";

import { setJobSelectionLoader } from "redux/slices/registrationSlice";
import * as actionTypes from "./actionTypes";
import instance from "./instance";

import {
  FetchActivity,
  fetchProgram,
  getApplicant,
  getApplicantSimplified,
  getParent,
  handleError,
  resetErrors,
} from "../actions";

import { enqueueAlert } from "redux/slices/alertSlice";
import {
  apiErrorCatcher,
  handleFileDownload,
} from "redux/slices/common-action-utils";
import { isValidValue } from "../../components/helpers/helper_functions";
import { removePrivatePricingInfo } from "components/programs/programDetails/programDetailsSlice";

export const FetchBusinessesList = (programName) => async () => {
  try {
    const res = await instance.get(
      `/businesses-list/${programName ? `?program=${programName}` : ""}`
    );
    return res.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const fetchRegistration = (registrationID) => async (dispatch) => {
  try {
    const res = await instance.get(`/registration/${registrationID}/`);
    const registration = res.data;

    dispatch({ type: actionTypes.FETCH_REGISTRATION, payload: registration });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
export const fetchActivityRegistration =
  (registrationID) => async (dispatch) => {
    try {
      const res = await instance.get(`/registrationsac/${registrationID}/`);
      const registrationsac = res.data;
      dispatch({
        type: actionTypes.FETCH_ACTIVITY_REGISTRATION,
        payload: registrationsac,
      });
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const FetchProgramsSearch = () => async (dispatch) => {
  try {
    const supervisorCode = localStorage.getItem("supervisor_code");
    const response = supervisorCode
      ? await instance.get(`/staff/programs/?supervisor_code=${supervisorCode}`)
      : await instance.get(`/staff/programs/`);
    const programs = response.data;
    return programs;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const Register =
  (programID, childrenList, parent, registrationData, fetchRest = true) =>
  async (dispatch) => {
    try {
      const res = await Promise.all(
        childrenList.map(async (child) => {
          let formData = new FormData();
          Object.keys(registrationData).forEach((key) => {
            formData.append(key, registrationData[key]);
          });
          formData.append(
            "userID",
            parent ? child.id : registrationData["userID"]
          );
          formData.append(
            "arabic_level",
            parent
              ? registrationData[`arabic_level${child.id}`]
              : registrationData["arabic_level"]
          );
          formData.append(
            "english_level",
            parent
              ? registrationData[`english_level${child.id}`]
              : registrationData["english_level"]
          );
          formData.append(
            "program_preferred_language",
            parent
              ? registrationData[`program_preferred_language${child.id}`]
              : registrationData["program_preferred_language"]
          );
          formData.append("is_parent", !!parent);
          formData.append("childrenList", childrenList);

          return await instance.post(
            `/programs/${programID}/register/`,
            formData
          );
        })
      ).then((results) => {
        dispatch(removePrivatePricingInfo(programID));
        return results.map((result) => result.data["ID"]);
      });

      if (fetchRest) {
        await dispatch(fetchProgram(programID));
        if (parent) await dispatch(getParent(parent));
        else await dispatch(getApplicantSimplified(registrationData.userID));
      }
      return res;
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);

      apiErrorCatcher(error, dispatch);

      return null;
    }
  };

export const updateRegistration =
  (programID, data, history = null) =>
  async (dispatch) => {
    try {
      await instance.put(`/programs/${programID}/register/`, data);
      if (history) history.push(`/programs/${programID}/slots/`);
      dispatch(resetErrors());
    } catch (error) {
      apiErrorCatcher(error, dispatch);
      await dispatch(handleError(error));
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const updateActivityRegistration =
  (activityID, data, history = null) =>
  async (dispatch) => {
    try {
      await instance.put(`/activities/${activityID}/register/`, data);
      dispatch(resetErrors());
    } catch (error) {
      await dispatch(handleError(error));
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const updateRegistrationStatus =
  (programID, loyacID, status, data) => async (dispatch) => {
    try {
      const res = await instance.patch(`/programs/${programID}/register/`, {
        status: status,
        loyac_id: loyacID,
        ...data,
      });
      dispatch(
        enqueueAlert({
          severity: "success",
          message: "Done",
        })
      );
      return res;
    } catch (error) {
      dispatch(handleError(error));
      return "error";
    }
  };

export const updateActivityRegistrationStatus =
  (activityID, loyacID, status) => async () => {
    try {
      await instance.patch(`/activities/${activityID}/register/`, {
        status: status,
        loyac_id: loyacID,
        activityID: activityID,
      });
      message.info("Success");
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const ExportSelectedRegistrations = (regObjs) => async () => {
  try {
    const data = JSON.stringify(regObjs.map((obj) => obj.id));
    const response = await instance.get(
      `/staff/export/registrations/selected/`,
      {
        params: { data, page_size: 1000 },
        responseType: "blob",
      }
    );

    handleFileDownload(response);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchCompaniesSearch = () => async (dispatch) => {
  try {
    const res = await instance.get(`/staff/companies/`);
    const companies = res.data;
    return companies;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const updateAllSearchRegistrationStatus =
  (queryParam, status) => async () => {
    const params = {};

    Object.keys(queryParam).map((key) => {
      if (isValidValue(queryParam[key]))
        if (key === "year") {
          if (
            typeof queryParam[key] === "string" ||
            queryParam[key] instanceof String
          )
            params[key] = moment(queryParam[key]).year();
          else params[key] = queryParam[key].year();
        } else params[key] = queryParam[key];
      return null;
    });

    try {
      await instance.get(`/staff/status/update/registrations/all/`, {
        params: { ...params, page_size: 1000, new_status: status },
      });
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const FetchEmailsList = (queryParam) => async () => {
  const params = {};

  Object.keys(queryParam).map((key) => {
    if (isValidValue(queryParam[key]))
      if (key === "year") {
        if (
          typeof queryParam[key] === "string" ||
          queryParam[key] instanceof String
        )
          params[key] = moment(queryParam[key]).year();
        else params[key] = queryParam[key].year();
      } else params[key] = queryParam[key];
    return null;
  });

  try {
    const response = await instance.get(`/staff/emails/all/`, {
      params: { ...params, page_size: 1000 },
    });

    return response.data.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const EmailAll = (emailInfo) => async (dispatch) => {
  try {
    const formData = new FormData();
    Object.keys(emailInfo).forEach((key) => {
      if (key === "emailsList")
        formData.append(key, JSON.stringify(emailInfo[key]));
      else formData.append(key, emailInfo[key]);
    });
    await instance.post(`/staff/emailAll/`, formData);
    dispatch(
      enqueueAlert({
        severity: "success",
        message: "Emails successfully sent!",
      })
    );
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const DownloadAllProfileImages = (queryParam) => async () => {
  const params = {};

  Object.keys(queryParam).map((key) => {
    if (isValidValue(queryParam[key]))
      if (key === "year") {
        if (
          typeof queryParam[key] === "string" ||
          queryParam[key] instanceof String
        )
          params[key] = moment(queryParam[key]).year();
        else params[key] = queryParam[key].year();
      } else params[key] = queryParam[key];
    return null;
  });

  try {
    const response = await instance.get(`/staff/export/pictures/all/`, {
      params: { ...params, page_size: 1000 },
      responseType: "blob",
    });
    const fileName = "Profile Images " + moment().format("DD-MM-YYYY") + ".zip";
    const headerLine = `attachment;filename="${fileName}"`;
    const startFileNameIndex = headerLine.indexOf('"') + 1;
    const endFileNameIndex = headerLine.lastIndexOf('"');
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/zip",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      headerLine.substring(startFileNameIndex, endFileNameIndex)
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const DownloadSelectedProfileImages = (regObjs) => async () => {
  try {
    const data = JSON.stringify(regObjs.map((obj) => obj.id));
    const response = await instance.get(`/staff/export/pictures/selected/`, {
      params: { data, page_size: 1000 },
      responseType: "blob",
    });
    const fileName = "Profile Images " + moment().format("DD-MM-YYYY") + ".zip";
    const headerLine = `attachment;filename="${fileName}"`;
    const startFileNameIndex = headerLine.indexOf('"') + 1;
    const endFileNameIndex = headerLine.lastIndexOf('"');
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/zip",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      headerLine.substring(startFileNameIndex, endFileNameIndex)
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const ExportAllSearchRegistration = (queryParam) => async () => {
  const params = {};

  const supervisorCode = localStorage.getItem("supervisor_code");

  Object.keys(queryParam).map((key) => {
    if (isValidValue(queryParam[key]))
      if (key === "year") {
        if (
          typeof queryParam[key] === "string" ||
          queryParam[key] instanceof String
        )
          params[key] = moment(queryParam[key]).year();
        else params[key] = queryParam[key].year();
      } else params[key] = queryParam[key];
    return null;
  });

  try {
    const response = await instance.get(
      supervisorCode
        ? `/staff/export/registrations/all/?supervisor_code=${supervisorCode}`
        : "/staff/export/registrations/all/",
      {
        params: { ...params, page_size: 1000 },
        responseType: "blob",
      }
    );
    const fileName =
      "Registrations List " + moment().format("DD-MM-YYYY") + ".xlsx";
    const headerLine = `attachment;filename="${fileName}"`;
    const startFileNameIndex = headerLine.indexOf('"') + 1;
    const endFileNameIndex = headerLine.lastIndexOf('"');
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      headerLine.substring(startFileNameIndex, endFileNameIndex)
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const updateSelectedActivityStatus =
  (registrationList, activity_status, activityID) => async () => {
    try {
      await Promise.all(
        registrationList.map(
          async (register) =>
            await instance.post(`activities/${activityID}/register/`, {
              registrationData: {
                activity_status: activity_status,
                loyac_id: register.user_id,
                activityID: activityID,
              },
            })
        )
      );
      message.info("Successfully invited ");
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const updateSelectedRegistrationStatus =
  (registrationList, status) => async () => {
    try {
      await Promise.all(
        registrationList.map(
          async (register) =>
            await instance.patch(`programs/${register.program_id}/register/`, {
              status: status,
              loyac_id: register.user_id,
            })
        )
      );
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const interviewResult =
  (registrationID, result, history) => async () => {
    try {
      await instance.post(`/registrations/${registrationID}/results/`, result);
      history.push(`/registrations/${registrationID}/results/`);
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const updateInterviewResult =
  (registrationID, result, history) => async () => {
    try {
      await instance.put(`/registrations/${registrationID}/results/`, result);
      history.push(`/registrations/${registrationID}/results/`);
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const secondInterviewResult =
  (registrationID, result, history) => async () => {
    try {
      await instance.post(
        `/registrations/${registrationID}/second_results/`,
        result
      );
      history.push(`/registrations/${registrationID}/second_results/`);
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const updateSecondInterviewResult =
  (registrationID, result, history) => async () => {
    try {
      await instance.put(
        `/registrations/${registrationID}/second_results/`,
        result
      );
      history.push(`/registrations/${registrationID}/second_results/`);
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const PayFees = (registrationID, paymentMethod) => async () => {
  try {
    const response = await instance.post(
      `/registrations/${registrationID}/pay/`,
      {
        paymentMethod,
      }
    );

    const data = response.data;
    const url = data.payment_url;

    return url;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
    return "error";
  }
};

export const addCompanyEvaluation =
  (registrationID, data, history) => async () => {
    try {
      await instance.post(
        `/registrations/${registrationID}/company_evaluation/`,
        data
      );
      if (history)
        history.push(`/registrations/${registrationID}/company_evaluation/`);
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const fetchCompanyEvaluation = (registrationID) => async (dispatch) => {
  try {
    const res = await instance.get(
      `/registrations/${registrationID}/company_evaluation/`
    );
    const evaluation = res.data;

    dispatch({
      type: actionTypes.FETCH_COMPANY_EVALUATION,
      payload: evaluation,
    });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const updateEvaluation = (registrationID, data, history) => async () => {
  try {
    await instance.put(`/registrations/${registrationID}/evaluation/`, data);
    history.push(`/registrations/${registrationID}/evaluation/`);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const generateOTC =
  (programID, applicantID, staffName) => async (dispatch) => {
    try {
      await instance.post(`/programs/${programID}/otc/`, {
        applicantID,
        generated_by: staffName,
      });
      dispatch(resetErrors());
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
      dispatch(handleError(error));
    }
  };

export const jobsiteSelectionResult =
  (registrationID, result, companyException, genderException) =>
  async (dispatch) => {
    const supervisorCode = localStorage.getItem("supervisor_code");
    dispatch(setJobSelectionLoader(true));

    try {
      await instance.post(
        `/registrations/${registrationID}/jobsite-results/?company_exception=${companyException}&gender_exception=${genderException}`,
        {
          ...result,
          supervisorCode: supervisorCode,
        }
      );
      return "success";
    } catch (error) {
      return {
        error: true,
        errorData: error.response ? error.response.data : error,
      };
    } finally {
      dispatch(setJobSelectionLoader(false));
    }
  };

export const createEvaluation = (registrationID, data, history) => async () => {
  try {
    await instance.post(`/registrations/${registrationID}/evaluation/`, data);
    history.push(`/registrations/${registrationID}/evaluation/`);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const createActivityEvaluation =
  (registrationID, data, history) => async () => {
    try {
      await instance.post(
        `/registrations/${registrationID}/activityevaluation/`,
        data
      );
      history.push(`/registrations/${registrationID}/activityevaluation/`);
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const updateJobsiteSelectionResult =
  (registrationID, result) => async () => {
    try {
      await instance.put(
        `/registrations/${registrationID}/jobsite-results/`,
        result
      );
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const activityRegister =
  (activityID, registrationData) => async (dispatch) => {
    try {
      const res = await Promise.all(
        await instance.post(`activities/${activityID}/register/`, {
          registrationData: {
            // userID: registrationData["userID"],
            activityName: registrationData["activity.name"],
          },
        })
      ).then((results) => results.map((result) => result.data["ID"]));

      await dispatch(FetchActivity(activityID));
      await dispatch(getApplicant(registrationData.userID));

      return res;
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const updateApplicantRole = (activityID, userID, role) => async () => {
  try {
    await instance.put(`/activities/${activityID}/register/`, {
      role: role,
      userID: userID,
      activityID: activityID,
    });
    message.info("Success");
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
export const centerSelection = (registrationID, result) => async () => {
  try {
    const response = await instance.post(
      `/registrations/${registrationID}/center-selection/`,
      {
        ...result,
      }
    );

    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
    if (error.response) return error.response.data;
  }
};

export const exportCompanyList = (regObjs) => async () => {
  try {
    const data = JSON.stringify(regObjs.map((obj) => obj.id));
    const response = await instance.get(`/staff/export/company/selected/`, {
      params: { data },
      responseType: "blob",
    });

    handleFileDownload(response);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const AssignProgramSupervisor = (programID) => async (dispatch) => {
  try {
    const response = await instance.put(
      `v2/reg/supervisor/assign/${programID}/`
    );
    dispatch(
      enqueueAlert({
        severity: "success",
        message: "Done",
      })
    );
    return response.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const ExportSMJReport = (queryParam) => async () => {
  const params = {};

  Object.keys(queryParam).map((key) => {
    if (isValidValue(queryParam[key]))
      if (key === "year") {
        if (
          typeof queryParam[key] === "string" ||
          queryParam[key] instanceof String
        )
          params[key] = moment(queryParam[key]).year();
        else params[key] = queryParam[key].year();
      } else params[key] = queryParam[key];
    return null;
  });

  try {
    const response = await instance.get("/staff/export/smj-report/", {
      params: { ...params, page_size: 1000 },
      responseType: "blob",
    });
    const fileName = "SMJ Report | " + moment().format("DD-MM-YYYY") + ".xlsx";
    const headerLine = `attachment;filename="${fileName}"`;
    const startFileNameIndex = headerLine.indexOf('"') + 1;
    const endFileNameIndex = headerLine.lastIndexOf('"');
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      headerLine.substring(startFileNameIndex, endFileNameIndex)
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
