import * as actionTypes from "./actionTypes";
import instance from "./instance";

// actions
import { handleError, resetErrors } from "../actions";
import { enqueueAlert } from "redux/slices/alertSlice";
import { removeEmpty } from "components/helpers/helper_functions";
import { apiErrorCatcher } from "redux/slices/common-action-utils";

export const BasicProgramInfoFetching = (programID) => async () => {
  try {
    const res = await instance.get(`/programs/${programID}/basic/`);
    const program = res.data;

    return program;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const fetchRegistrationPrograms = () => async (dispatch) => {
  try {
    const res = await instance.get(`/registrations_programs/`);
    const registrations_programs = res.data;

    dispatch({
      type: actionTypes.FETCH_REGISTRATION_PROGRAMS,
      payload: registrations_programs,
    });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const fetchPrograms = () => async (dispatch) => {
  try {
    const res = await instance.get(`/programs/`);
    const programs = res.data;

    dispatch({ type: actionTypes.FETCH_PROGRAMS, payload: programs });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const NewFetchPrograms = (query) => async () => {
  try {
    const res = await instance.get(`/programs_list/new/?${query}`);
    const programs = res.data;

    return programs;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchProgramsList = (allPrograms) => async (dispatch) => {
  try {
    const supervisorCode = localStorage.getItem("supervisor_code");

    allPrograms =
      !window.location.href.includes("community_center/") || allPrograms;
    let response;
    if (allPrograms) response = await instance.get("/programs_list/");
    else
      response = supervisorCode
        ? await instance.get(
            `/programs_list/?supervisor_code=${supervisorCode}`
          )
        : await instance.get("/programs_list/");
    const programs = response?.data;
    dispatch({ type: actionTypes.FETCH_PROGRAMS_LIST, payload: programs });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchAllProgramsAndInitiatives = () => async () => {
  try {
    const userType = localStorage.getItem("user_type");
    const supervisorCode = localStorage.getItem("supervisor_code");
    const response = supervisorCode
      ? await instance.get(
          `/programs_and_initiatives/?supervisor_code=${supervisorCode}`
        )
      : await instance.get(
          `/programs_and_initiatives/?usher=${userType === "is_lsv_usher"}`
        );
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const PublicProgramDetailsFetching = (programID) => async () => {
  try {
    const res = await instance.get(`/new/programs/${programID}/`);
    const program = res.data;

    return program;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const simpleProgramFetching = (programID) => async () => {
  try {
    const res = await instance.get(`/programs/${programID}/simple_fetching/`);
    const program = res.data;
    return program;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchSlotsList = (programID) => async (dispatch) => {
  try {
    const res = await instance.get(
      `/programs/${programID}/slots/not-paginated/`
    );
    const slots = res.data;

    dispatch({ type: actionTypes.FETCH_SLOTS_LIST, payload: slots });
    return slots;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const fetchProgram =
  (programID, isSurvey = false) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.SET_PROGRAM_LOADER, payload: true });

    try {
      let res;

      if (isSurvey) {
        res = await instance.get(`/program/${programID}/?survey=true`);
      } else {
        res = await instance.get(`/program/${programID}/`);
      }
      const program = res.data;
      dispatch({ type: actionTypes.FETCH_PROGRAM, payload: program });
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    } finally {
      dispatch({ type: actionTypes.SET_PROGRAM_LOADER, payload: false });
    }
  };

export const simplifiedFetchProgram = (programID) => async (dispatch) => {
  try {
    const res = await instance.get(`/program/${programID}/simplified/`);
    const program = res.data;

    return program;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const simplifiedFetchSlotsProgram =
  (programID, type, companyID) => async (dispatch) => {
    try {
      const params = companyID ? `&companyID=${companyID}` : "";
      const response = await instance.get(
        `/program/${programID}/simplified/slots/?type=${type}` + params
      );
      return response.data;
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const FetchProgramEntrySurvey =
  (programID, childId) => async (dispatch) => {
    try {
      // Create a query string with the child_id parameter if childId is not null or empty
      const queryString = childId ? `?child_id=${childId}` : "";

      // Append the query string to the request URL
      const res = await instance.get(
        `/program/${programID}/entry-survey/${queryString}`
      );

      const survey = res.data;

      dispatch({ type: actionTypes.FETCH_PROGRAM_SURVEY, payload: survey });
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);

      return null;
    }
  };

export const addProgram = (programData, history, cb) => async (dispatch) => {
  try {
    const formData = new FormData();
    Object.keys(programData).forEach((key) => {
      const value = programData[key];
      if (key === "program_gallery") {
        value.forEach((element, index) => {
          formData.append("program_gallery", element?.image);
        });
      }
      // Check if the value is an array and convert it to a JSON string
      if (key === "allowed_nationality" || key === "smjOrientationDetails") {
        formData.append(key, JSON.stringify(value));
      } else if (value === undefined) {
        formData.append(key, "");
      } else {
        formData.append(key, value);
      }
    });

    // Remove files if they hadn't been update, because their format will be wrong.
    if (typeof formData.get("image") === "string") formData.delete("image");
    if (typeof formData.get("arabic_image") === "string")
      formData.delete("arabic_image");
    if (typeof formData.get("timeline") === "string")
      formData.delete("timeline");
    if (typeof formData.get("arabic_timeline") === "string")
      formData.delete("arabic_timeline");
    if (typeof formData.get("trainer_image") === "string")
      formData.delete("trainer_image");
    if (typeof formData.get("contract") === "string")
      formData.delete("contract");
    if (typeof formData.get("contract_ar") === "string")
      formData.delete("contract_ar");

    const res = await instance.post(`/programs/`, formData);
    const { data } = res;
    cb(data);

    dispatch(resetErrors());

    if (data.program_type === "Main Program (SMJ)")
      history.push(`/programs/${data.id}/months`);
    else if (programData.payment_scheme === "Variant Pricing")
      history.push(`/programs/${data.id}/custom-pricing/?mode=create`);
    else history.push(`/programs/${data.id}/`);
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateProgram =
  (programID, programData, history, cb) => async (dispatch) => {
    try {
      const formData = new FormData();
      Object.keys(programData).forEach((key) => {
        const value = programData[key];

        if (key === "program_gallery") {
          value.forEach((element, idx) => {
            if (element.id) {
              // if it has changed
              if (typeof element.image !== "string") {
                formData.append(`images||${element.id}`, element.image);
                formData.append("imagesToEdit", element.id);
              }
              // send the updated images ( it wont include the deleted images )
              // in the backend compare with updated and existing and delete the
              // difference
              formData.append("updatedImages", element.id);
            }
            // new image
            else {
              formData.append("program_gallery", element.image);
            }
          });
        }

        // Check if the value is an array and convert it to a JSON string
        if (key === "allowed_nationality") {
          formData.append(key, JSON.stringify(value));
        } else if (value == null) {
          formData.append(key, "");
        } else {
          formData.append(key, value);
        }
      });

      // Remove fields if they hadn't been update, because their format will be wrong.
      if (typeof formData.get("image") === "string") formData.delete("image");
      if (typeof formData.get("arabic_image") === "string")
        formData.delete("arabic_image");
      if (typeof formData.get("timeline") === "string")
        formData.delete("timeline");
      if (typeof formData.get("arabic_timeline") === "string")
        formData.delete("arabic_timeline");
      if (typeof formData.get("trainer_image") === "string")
        formData.delete("trainer_image");
      if (
        typeof formData.get("interview_attachment") === "string" &&
        formData.get("interview_attachment")
      )
        formData.delete("interview_attachment");
      if (typeof formData.get("contract") === "string")
        formData.delete("contract");
      if (typeof formData.get("contract_ar") === "string")
        formData.delete("contract_ar");
      const res = await instance.put(`/programs/${programID}/`, formData);
      const { data } = res;
      if (cb) cb(data);

      if (history) {
        if (programData?.payment_scheme === "Variant Pricing")
          history.push(`/programs/${data.id}/custom-pricing/?mode=create`);
        else history.push(`/programs/${programID}/`);
      }
    } catch (error) {
      apiErrorCatcher(error, dispatch);
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const addInterviewSlots =
  (programID, slotsList, history) => async () => {
    try {
      await Promise.all(
        slotsList.map((slot, index) => {
          slot = {
            ...slot,
            index,
          };
          return instance.post(`programs/${programID}/slot/`, slot);
        })
      );
      history.push(`/programs/${programID}/slots/`);
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const UpdateInterviewSlot =
  (programID, slotID, slotData) => async (dispatch) => {
    try {
      await instance.put(`/programs/${programID}/slot/${slotID}/`, slotData);
    } catch (error) {
      await dispatch(handleError(error));
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const DeleteSlot = (programID) => async () => {
  try {
    await instance.delete(`programs/${programID}/slot/`);
  } catch (err) {
    console.error(err);
  }
};

export const AddUpdateAttendance =
  (programID, date, attendanceList) => async (dispatch) => {
    try {
      await Promise.all(
        attendanceList
          .filter((attendance) => !!attendance["status"])
          .map((attendance) => {
            attendance["date"] = new Date(
              date.getTime() - date.getTimezoneOffset() * 60000
            ).toISOString();
            return instance.post(
              `/programs/${programID}/attendance/`,
              attendance
            );
          })
      );
      dispatch(resetErrors());
    } catch (error) {
      dispatch(handleError(error));
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const fetchAttendance = (programID, applicantID) => async (dispatch) => {
  try {
    const res = await instance.get(
      `/programs/${programID}/attendance/${applicantID}/`
    );
    const programs = res.data;

    dispatch({ type: actionTypes.FETCH_ATTENDANCES, payload: programs });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const fetchGroups = (programID) => async (dispatch) => {
  try {
    const res = await instance.get(`/programs/${programID}/group/`);
    const groups = res.data;

    dispatch({ type: actionTypes.FETCH_GROUPS, payload: groups });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const updateGroup =
  (programID, groupData, history) => async (dispatch) => {
    try {
      await instance.put(`/programs/${programID}/group/`, groupData);
      history.push(`/programs/${programID}/groups/`);
    } catch (error) {
      await dispatch(handleError(error));
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const addGroup = (programID, groupData, history) => async (dispatch) => {
  try {
    const res = await instance.post(`programs/${programID}/group/`, groupData);
    const id = res.data.id;
    groupData["group_id"] = id;
    await dispatch(updateGroup(programID, groupData, history));
    dispatch(resetErrors());
    history.push(`/programs/${programID}/groups/`);
  } catch (error) {
    dispatch(handleError(error));
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const fetchAgeGroups = (programID) => async (dispatch) => {
  try {
    const res = await instance.get(`/programs/${programID}/age_group/`);
    const age_groups = res.data;

    dispatch({ type: actionTypes.FETCH_AGE_GROUPS, payload: age_groups });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const addAgeGroup = (programID, ageGroupData) => async () => {
  try {
    await instance.post(`programs/${programID}/age_group/`, ageGroupData);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const NewWorkshop = (programID, data) => async (dispatch) => {
  try {
    await instance.post(`programs/${programID}/workshop/add/`, data);
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const EditWorkshop = (workshopID, data) => async () => {
  try {
    await instance.put(`/workshops/${workshopID}/`, { ...data });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const DeleteWorkshop = (workshopID) => async () => {
  try {
    await instance.delete(`/workshops/${workshopID}/`);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchWorkshopsList = (programID) => async (dispatch) => {
  try {
    const response = await instance.get(`programs/${programID}/workshops/`);
    return response.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const AddOrEditMonth = (programID, data) => async (dispatch) => {
  try {
    await Promise.all(
      data.map(async (obj) => {
        await instance.post(
          `programs/${programID}/month/add/`,
          removeEmpty(obj)
        );
      })
    );
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const FetchMonthsList = (filters) => async (dispatch) => {
  try {
    const params = new URLSearchParams(filters);
    const response = await instance.get(
      `programs/months/?${params.toString()}`
    );

    return response.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const FetchProgramPricingMethods = (programID) => async (dispatch) => {
  try {
    const response = await instance.get(
      `programs/pricing-methods/?programID=${programID}`
    );

    return response.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const FetchProgramPricingMethod = (id, pgID) => async (dispatch) => {
  try {
    const response = await instance.get(
      `programs/pricing-methods/${id}/?programID=${pgID}`
    );
    return response.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};
export const AddProgramPricingMethod = (payload) => async (dispatch) => {
  try {
    const response = await instance.post(`programs/pricing-methods/`, payload);
    const data = response.data;
    if (data) {
      dispatch(
        enqueueAlert({
          severity: "success",
          message: "Sucessfully Created Pricing",
        })
      );
    }
    return data;
  } catch (error) {
    dispatch(apiErrorCatcher(error, dispatch));
  }
};
export const EditProgramPricingMethod = (payload) => async (dispatch) => {
  try {
    const response = await instance.patch(
      `programs/pricing-methods/${payload.id}/`,
      payload
    );
    const data = response.data;
    if (data) {
      dispatch(
        enqueueAlert({
          severity: "success",
          message: "Sucessfully Edited Pricing",
        })
      );
    }
    return data;
  } catch (error) {
    dispatch(apiErrorCatcher(error, dispatch));
  }
};
export const DeleteProgramPricingMethod = (id) => async (dispatch) => {
  try {
    const response = await instance.delete(`programs/pricing-methods/${id}/`);
    const data = response.data;
    if (data) {
      dispatch(
        enqueueAlert({
          severity: "success",
          message: "Sucessfully Deleted Pricing",
        })
      );
    }
    return data;
  } catch (error) {
    dispatch(apiErrorCatcher(error, dispatch));
  }
};
