import { commonLinks } from "components/helpers/commonLinks";
import { TFunction } from "i18next";
// Add a new object using the interface to create a new navbar link
export type NavbarOptionsNode = {
  title: string;
  isChildren?: boolean;
  to?: string;
  badgeValue?: string | number;
  children?: NavbarOptionsNode[];
};
type t = TFunction<"translation", undefined>;

export const commonNavigationLinks = (t: t) => ({
  programs: {
    title: t("navigation.common.programs"),
    to: commonLinks.homeLink,
  },
  requestsList: {
    title: t("navigation.common.requestsList"),
    to: "/requests/",
  },
  invitationsList: {
    title: t("navigation.common.invitationsList"),
    to: "/invitations/",
  },
  commonThreads: {
    title: t("navigation.common.threads"),
    isChildren: true,
    badgeValue: localStorage.getItem("threads") || 0,
    children: [
      {
        title: t("navigation.common.responded"),
        to: "/threads/responded/",
      },
      {
        title: t("navigation.common.notResponded"),
        to: "/threads/not/responded/",
      },
    ],
  },
});

export const commonTitles = (t: t, to: string) => ({
  myRequests: {
    title: t("navigation.common.myRequests"),
    to,
  },
  profile: {
    title: t("navigation.common.profile"),
    to,
  },
  history: {
    title: t("navigation.common.history"),
    to,
  },
  requestsList: { ...commonNavigationLinks(t).requestsList, to },
  publicProgram: { title: t("navigation.common.publicProgram"), to },
  exclusiveProgram: { title: t("navigation.common.exclusiveProgram"), to },
  lsvInitiative: { title: t("navigation.common.lsvInitiative"), to },
});
