// Actions
import {
  FETCH_PROGRAM,
  FETCH_PROGRAMS,
  FETCH_REGISTRATION_PROGRAMS,
  FETCH_ATTENDANCES,
  FETCH_GROUPS,
  FETCH_GROUP,
  FETCH_AGE_GROUPS,
  FETCH_PROGRAMS_LIST,
  FETCH_SLOTS_LIST,
  FETCH_PROGRAM_SURVEY,
  SET_PROGRAM_LOADER,
} from "../actions/actionTypes";

const initialState = {
  program: "",
  programLoading: true,
  programs: [],
  programsLoading: true,
  registrations_programs: [],
  registrations_programsLoading: true,
  attendance: [],
  attendanceLoading: true,
  groups: [],
  groupsLoading: true,
  group: [],
  groupLoading: true,
  age_groups: [],
  age_groupsLoading: true,
  programsList: [],
  programsListLoading: true,
  slotsList: [],
  slotsListLoading: true,
};

export const programsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROGRAM:
      return {
        ...state,
        program: action.payload,
        programLoading: false,
      };
    case SET_PROGRAM_LOADER:
      return {
        ...state,
        programLoading: action.payload,
      };
    case FETCH_PROGRAM_SURVEY:
      return {
        ...state,
        survey: action.payload,
      };
    case FETCH_REGISTRATION_PROGRAMS:
      return {
        ...state,
        registrations_programs: action.payload,
        registrations_programsLoading: false,
      };
    case FETCH_PROGRAMS:
      return {
        ...state,
        programs: action.payload,
        programsLoading: false,
      };
    case FETCH_ATTENDANCES:
      return {
        ...state,
        attendance: action.payload,
        attendanceLoading: false,
      };
    case FETCH_GROUPS:
      return {
        ...state,
        groups: action.payload,
        groupsLoading: false,
      };
    case FETCH_GROUP:
      return {
        ...state,
        group: action.payload,
        groupLoading: false,
      };
    case FETCH_AGE_GROUPS:
      return {
        ...state,
        age_groups: action.payload,
        age_groupsLoading: false,
      };
    case FETCH_PROGRAMS_LIST:
      return {
        ...state,
        programsList: action.payload,
        programsListLoading: false,
      };
    case FETCH_SLOTS_LIST:
      return {
        ...state,
        slotsList: action.payload,
        slotsListLoading: false,
      };
    default:
      return state;
  }
};

export default programsReducer;
