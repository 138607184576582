import { useTranslation } from "react-i18next";
import CommonNavigation from "../CommonNavigation";
import {
  NavbarOptionsNode,
  commonNavigationLinks,
  commonTitles,
} from "./IOption";

function CompanyOptions() {
  const { t } = useTranslation();

  const data: NavbarOptionsNode[] = [
    commonNavigationLinks(t).programs,
    commonTitles(t, "/requests/").myRequests,
    {
      title: t("navigation.companyOptions.requestInterns"),
      to: "/requests/add/",
    },
  ];
  return <CommonNavigation data={data} />;
}

export default CompanyOptions;
