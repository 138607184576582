import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CommonNavigation from "../CommonNavigation";
import {
  NavbarOptionsNode,
  commonNavigationLinks,
  commonTitles,
} from "./IOption";

function CommunityCenterOptions() {
  const user = useSelector((state: any) => state.rootAuth.user);

  const { t } = useTranslation();
  const translation = (key: string) => t(`navigation.communityCenter.${key}`);
  const data: NavbarOptionsNode[] = [
    commonNavigationLinks(t).programs,
    commonTitles(t, "/volunteer_requests/").myRequests,
    {
      title: translation("requestVolunteers"),
      to: `/community_center/${user?.user_id}/requests/add/`,
    },
  ];
  return <CommonNavigation data={data} />;
}

export default CommunityCenterOptions;
