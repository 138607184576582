// Actions
import { GET_APPLICANTS, GET_CREDITS } from "../actions/actionTypes";

const initialState = {
  applicants: [],
  filteredApplicants: [],
  applicantsLoading: true,
  credits: [],
  creditLoading: true,
};

export const applicantReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPLICANTS:
      return {
        ...state,
        applicants: action.payload,
        filteredApplicants: action.payload,
        applicantsLoading: false,
      };
    case GET_CREDITS:
      return {
        ...state,
        credits: action.payload,
        creditLoading: false,
      };
    default:
      return state;
  }
};

export default applicantReducer;
