// rtlTheme.js
import { createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import createCache from "@emotion/cache";

// Inherit the base theme
import theme from "./default";

// Create a new theme with RTL modifications
const rtlTheme = createTheme({
  // Inherit properties from the base theme
  ...theme,
  direction: "rtl",
  // Override any specific RTL modifications here
  typography: {
    fontFamily: "IBMPlexSansArabic", // Replace with your desired font family
    allVariants: {
      letterSpacing: 0, // Set letter spacing to 0 to remove it
    },
  },
});

export default rtlTheme;

export const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
