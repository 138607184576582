import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import DetailDialogTitle from "components/common/Materialui/DetailDialog/DetailDialogTitle";
import DialogBase from "components/common/Materialui/DetailDialog/DialogBase";
import useLogoutState from "components/common/components/logout/useLogoutState";
import { flexCenter } from "components/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function UserSettingsModal({
  open,
  handleClose,
  subscribtionStatus,
  setsubscribtionStatus,
}) {
  const { saveUserEmailStatus, settingsLoading } = useLogoutState();
  const [emailsubscribeCheck, setemailsubscribeCheck] = useState(
    subscribtionStatus === "subscribed" ? true : false
  );
  const email = useSelector((state) => state.rootAuth.user.email);
  useEffect(() => {
    setemailsubscribeCheck(subscribtionStatus === "subscribed" ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribtionStatus]);
  const { t } = useTranslation();
  return (
    <DialogBase
      open={open}
      onClose={handleClose}
      hideDialog={handleClose}
      title={
        <DetailDialogTitle title={t("common.settings")} onClose={handleClose} />
      }
      content={
        <Box bgcolor={"white"} p={4}>
          <Typography variant="h5" pb={"20px"}>
            {t("auth.notificationSettings")}
          </Typography>
          <Divider />
          <Box px={"30px"} py={"10px"}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailsubscribeCheck}
                  onChange={(e) => {
                    setemailsubscribeCheck(e.target.checked);
                  }}
                />
              }
              label={t("auth.agreeSubscription")}
            />
          </Box>
          <Box {...flexCenter}>
            {settingsLoading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                onClick={async () => {
                  const data = await saveUserEmailStatus(
                    emailsubscribeCheck,
                    email
                  );
                  if (data === "subscribed" || data === "unsubscribed") {
                    setsubscribtionStatus(data);
                  }
                  handleClose();
                }}
                sx={{ minWidth: "300px" }}
              >
                {t("common.save")}
              </Button>
            )}
          </Box>
        </Box>
      }
    />
  );
}

export default UserSettingsModal;

// /*
