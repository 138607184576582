// Applicant
export const SET_APPLICANT = "SET_APPLICANT";
export const SET_APPLICANT_COMPLEX = "SET_APPLICANT_COMPLEX";
export const GET_APPLICANTS = "GET_APPLICANTS";
export const GET_CREDITS = "GET_CREDITS";

// Auth
export const SET_ERRORS = "SET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USER_LOADING = "SET_USER_LOADING";
// Branch
export const SET_BRANCH = "SET_BRANCH";
export const FETCH_BRANCH_REQUESTS = "FETCH_BRANCH_REQUESTS";

// Company
export const SET_COMPANY = "SET_COMPANY";

export const FETCH_COMPANIES = "FETCH_COMPANIES";

// Evaluation
export const FETCH_COMPANY_EVALUATION = "FETCH_COMPANY_EVALUATION";

// Parents
export const SET_PARENT = "SET_PARENT";

// Program
export const FETCH_PROGRAM = "FETCH_PROGRAM";
export const FETCH_PROGRAMS_LIST = "FETCH_PROGRAMS_LIST";
export const FETCH_REGISTRATION_PROGRAMS = "FETCH_REGISTRATION_PROGRAMS";
export const FETCH_PROGRAMS = "FETCH_PROGRAMS";
export const FETCH_ATTENDANCES = "FETCH_ATTENDANCES";
export const FETCH_GROUPS = "FETCH_GROUPS";
export const FETCH_GROUP = "FETCH_GROUP";
export const FETCH_AGE_GROUPS = "FETCH_AGE_GROUPS";
export const FETCH_SLOTS_LIST = "FETCH_SLOTS_LIST";
export const FETCH_PROGRAM_SURVEY = "FETCH_PROGRAM_SURVEY";
export const SET_PROGRAM_LOADER = "SET_PROGRAM_LOADER";

// Registrations
export const FETCH_REGISTRATION = "FETCH_REGISTRATION";
export const FETCH_REGISTRATIONS = "FETCH_REGISTRATIONS";
export const FETCH_ACTIVITY_REGISTRATION = "FETCH_ACTIVITY_REGISTRATION";
export const FETCH_ACTIVITY_REGISTRATIONS = "FETCH_ACTIVITY_REGISTRATIONS";

// Request
export const FETCH_REQUESTS = "FETCH_REQUESTS";
export const FETCH_REQUEST_DETAILS = "FETCH_REQUEST_DETAILS";
export const FETCH_REQUEST_PROGRAM = "FETCH_REQUEST_PROGRAM";
export const FILTER_REQUESTS = "FILTER_REQUESTS";

// Staff
export const SET_STAFF = "SET_STAFF";
export const SET_SUPERVISOR = "SET_SUPERVISOR";

// Supervisors
export const GET_SUPERVISORS = "GET_SUPERVISORS";
export const GET_FORMS = "GET_FORMS";

// Tables
export const FETCH_AREAS = "FETCH_AREAS";
export const FETCH_MAJORS = "FETCH_MAJORS";
export const FETCH_FACULTIES = "FETCH_FACULTIES";
export const FETCH_UNIVERSITIES = "FETCH_UNIVERSITIES";
export const FETCH_GOVERNORATES = "FETCH_GOVERNORATES";
export const FETCH_NATIONALITIES = "FETCH_NATIONALITIES";

// Threads
export const FETCH_THREAD = "FETCH_THREAD";
export const FETCH_THREADS = "FETCH_THREADS";

// Activities
export const FETCH_ACTIVITIES_LIST = "FETCH_ACTIVITIES_LIST";
export const FETCH_ACTIVITY = "FETCH_ACTIVITY";
export const FETCH_ACTIVITIES = "FETCH_ACTIVITIES";

export const SET_USHER_OBJ = "SET_USHER_OBJ";

//Community Center
export const SET_COMMUNITY_CENTER = "SET_COMMUNITY_CENTER";
export const FETCH_COMMUNITY_CENTER = "FETCH_COMMUNITY_CENTER";
export const FETCH_COMMUNITY_CENTERS = "FETCH_COMMUNITY_CENTERS";
