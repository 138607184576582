import React from "react";
import { CircularProgress, Typography } from "@mui/material";
import Div from "../Div";
interface Props {
  description?: string;
}

const Loader = ({ description }: Props) => {
  return (
    <Div
      data-testid="loader"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(3),
        m: "auto",
      }}
    >
      <CircularProgress />
      {description && (
        <Typography variant={"h6"} color={"text.secondary"} mt={2}>
          {description}
        </Typography>
      )}
    </Div>
  );
};

export default Loader;
