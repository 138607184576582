import React from "react";
import { Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomContainer from "components/common/Materialui/Container/CustomContainer";
import { muiGridContainerSpacing } from "components/common/js/constants";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <CustomContainer>
      <Grid
        container
        spacing={muiGridContainerSpacing}
        sx={{ height: "80vh" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h1" data-testid="notfound-header-testid" mb={4}>
            {t("common.maintenanceTitle")}
          </Typography>
          <Typography variant="h4" mb={2}>
            {t("common.maintenanceDescription")}
          </Typography>
        </Grid>
      </Grid>
    </CustomContainer>
  );
};

export default NotFound;
