import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Badge } from "@mui/material";
import MuiMenu from "components/common/components/mui_menu";
import { NavbarOptionsNode } from "../options/IOption";
import NavbarChildMenu from "./NavbarChildMenu";

function NavbarMenu({
  node,
  onDrawerClose,
}: {
  node: NavbarOptionsNode;
  onDrawerClose: any;
}) {
  return (
    <MuiMenu title={navbarTitleItem(node.title, node.badgeValue)}>
      {(handleTopClose: Function) => {
        const handleTopCloseFunc = () => {
          onDrawerClose();
          handleTopClose();
        };
        return NavbarChildMenu(node, handleTopCloseFunc);
      }}
    </MuiMenu>
  );
}

export default NavbarMenu;

export const navbarTitleItem =
  (title: string, badgeValue: string | undefined | number) =>
  (open: boolean) => {
    const tempItem = (
      <>
        {title}
        <KeyboardArrowDownIcon
          sx={{
            transform: `rotate(${open ? "180" : "0"}deg)`,
            transition: "transform 0.3s ease",
          }}
        />
      </>
    );
    return badgeValue ? (
      <Badge color="primary" badgeContent={badgeValue}>
        {tempItem}
      </Badge>
    ) : (
      tempItem
    );
  };
