import React from "react";
import Loader from "components/common/Materialui/Loader/Loader";

// Styles
import LoadingPageStyles from "./styles";

const LoadingPage = () => {
  return (
    <LoadingPageStyles id="loading-div">
      {/* <img src="/icons/loading.gif" alt="" /> */}
      <Loader />
    </LoadingPageStyles>
  );
};

export default LoadingPage;
