import * as actionTypes from "./actionTypes";
import instance from "./instance";

// Actions
import { linkWithLanguage } from "components/helpers/commonLinks";
import { getCreditsAPI } from "redux/services/applicants";
import {
  apiErrorCatcher,
  createQuery,
  handleFileDownload,
} from "redux/slices/common-action-utils";
import { handleError, resetErrors } from "../actions";

export const setApplicant = (applicant) => ({
  type: actionTypes.SET_APPLICANT,
  payload: applicant,
});

export const getApplicant = (userID) => async (dispatch) => {
  try {
    const res = await instance.get(`/applicants/${userID}/`);
    const applicant = res.data;

    dispatch({
      type: actionTypes.SET_APPLICANT_COMPLEX,
      payload: applicant,
    });
    return applicant;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const getApplicantSimplified = (userID) => async (dispatch) => {
  try {
    const res = await instance.get(`/applicants/${userID}/simple/`);
    const applicant = res.data;

    dispatch(setApplicant(applicant));
    return applicant;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const getApplicantInfo = (userID) => async (dispatch) => {
  try {
    const response = await instance.get(`/applicants/${userID}/info/`);
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const GetApplicantProfile = (userID) => async () => {
  try {
    const response = await instance.get(`/applicants/${userID}/profile/`);
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
export const GetLeaderBoardInfo = (userID) => async () => {
  try {
    const response = await instance.get(`/applicants/${userID}/leaderboard/`);
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const UpdateApplicantProfile =
  (userID, profileData, history) => async (dispatch) => {
    try {
      let formData = new FormData();
      Object.keys(profileData).forEach((key) => {
        key !== "education" && key !== "general" && key !== "user"
          ? formData.append(key, profileData[key])
          : formData.append(key, JSON.stringify(profileData[key]));
      });
      await instance.put(`/applicants/${userID}/`, formData);

      dispatch(resetErrors());

      history.push(linkWithLanguage(`/applicant/${userID}/profile/`));
    } catch (error) {
      await dispatch(handleError(error));
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const addCredits = (userData) => async (dispatch) => {
  try {
    const res = await instance.post(`/credits/`, userData);
    return res;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
    apiErrorCatcher(error, dispatch);
    return "Rejected";
  }
};
export const addManualHours = (userData) => async (dispatch) => {
  try {
    const res = await instance.post(`applicants/manual-hours/`, userData);
    return res;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
    apiErrorCatcher(error, dispatch);
    return "Rejected";
  }
};
export const getManualHours = (params) => async (dispatch) => {
  try {
    const res = await instance.get(`applicants/manual-hours/?${params}`);
    return res;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
    apiErrorCatcher(error, dispatch);
    return "failed";
  }
};
export const changeManualHoursStatus = (data) => async (dispatch) => {
  const { id, ...rest } = data;
  try {
    const res = await instance.patch(`applicants/manual-hours/${id}/`, rest);
    return res;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
    apiErrorCatcher(error, dispatch);
    return "failed";
  }
};

export const ChangeApplicantToSupervisor = (applicant_id) => async () => {
  try {
    await instance.put(`/applicants/supervisor/`, applicant_id);
    window.location.reload();
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchApplicantHistoryNotPaginated = (userID) => async () => {
  try {
    const response = await instance.get(
      `applicants/${userID}/history/not-paginated/`
    );
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const exportCreditList = (payload) => async (dispatch, getState) => {
  try {
    const state = getState();

    const { creditFilters } = state.rootApplicantSlice;
    const queryParams = { ...creditFilters, ...payload };
    const query = "?" + createQuery({ filter: queryParams });
    const response = await getCreditsAPI(query, "blob");
    handleFileDownload(response);
  } catch (error) {
    apiErrorCatcher(error, dispatch);
    return "Rejected";
  }
};

export const downloadCertificate = (regId, fullName) => async (dispatch) => {
  try {
    const { data } = await instance.get(
      `applicants/download-certificate/${regId}/`,
      {
        responseType: "blob",
      }
    );
    if (data) {
      const link = document.createElement("a");
      const url = URL.createObjectURL(data);
      link.href = url;
      if (fullName) {
        link.download = `${fullName} - certificate.pdf`;
      } else {
        link.download = "certificate.pdf";
      }

      // Append to the DOM, trigger the download, and remove the element
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    }
  } catch (error) {
    apiErrorCatcher(error, dispatch);
  }
};
