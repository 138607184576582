import instance from "./instance";

import { handleError } from "../actions";

export const NewRequirement =
  (volunteerRequestID, RequirementsList) => async (dispatch) => {
    try {
      await Promise.all(
        RequirementsList.map(async (data) => {
          const requirementData = {
            ...data,
            volunteerRequest: volunteerRequestID,
          };
          const formData = new FormData();
          Object.keys(requirementData).forEach((key) => {
            formData.append(key, requirementData[key]);
          });
          await instance.post(
            `volunteerRequests/${volunteerRequestID}/requirement/add/`,
            formData
          );
        })
      );
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const UpdateVolunteerRequestStatus =
  (requestID, status, withdraw_reasons) => async (dispatch) => {
    try {
      await instance.patch(`/volunteer_requests/${requestID}/`, {
        status,
        withdraw_reasons,
      });
      window.location.reload();
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const FetchVolunteerRequestDetails = (requestID) => async () => {
  try {
    const result = await instance.get(`/volunteer_requests/new/${requestID}/`);
    return result.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
