/* eslint-disable react-hooks/exhaustive-deps */
import MenuIcon from "@mui/icons-material/Menu";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  MenuItem,
  Toolbar,
  Button,
} from "@mui/material";
import CustomContainer from "components/common/Materialui/Container/CustomContainer";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/reducers/hooks";

import LanguageSwitcherButton from "components/common/Materialui/LanguageSwitcher/LanguageSwitcher";
import Logout from "components/common/components/logout";
import { getKeyStorage } from "components/helpers/helper_functions";
import { navbarItemStyle } from "components/styles";
// Styles
import { useTranslation } from "react-i18next";
import { getBasket, setBasket } from "redux/slices/productSlice";
import Logo from "../logo";
import CartIcon from "./CartIcon";
import ApplicantOptions from "./options/ApplicantOptions";
import CommunityCenterOptions from "./options/CommunityCenterOptions";
import CompanyOptions from "./options/CompanyOptions";
import LoyacStaffOptions from "./options/LoyacStaffOptions.tsx";
import ParentOptions from "./options/ParentOptions";
import SupervisorOptions from "./options/SupervisorOptions";
import UsherOptions from "./options/UsherOptions";
import { toggleDrawer } from "./slice";
import { commonLinks, linkWithLanguage } from "components/helpers/commonLinks";
import { defaultColors } from "components/common/Materialui/themes/main/default";

function Navbar() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.rootAuth.user);

  const { basket } = useAppSelector(({ rootProductSlice }) => rootProductSlice);
  const { drawerOpen } = useAppSelector(({ navbar }) => navbar);
  const getUpdateBasket = async () => {
    const currentBasket = getKeyStorage("basket");
    if (!currentBasket) return;

    const payload = {
      basketId: currentBasket,
    };

    const { payload: resPayload } = await dispatch(getBasket(payload));

    if (resPayload) {
      const { status } = resPayload;

      if (status === "SUBMITTED") {
        await dispatch(setBasket(null));
      }
    }
  };
  useEffect(() => {
    getUpdateBasket();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  const onLoginClick = () => history.push(linkWithLanguage("/accounts/"));
  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={3}
      sx={{ mb: 1, textAlign: "unset" }}
    >
      <CustomContainer maxWidth="xl">
        <Toolbar disableGutters>
          {/* Mobile View Side Drawer */}
          <Box {...responsiveBoxProps}>
            <IconButton onClick={() => dispatch(toggleDrawer(true))}>
              <MenuIcon />
            </IconButton>
            <Drawer
              open={drawerOpen}
              onClose={() => dispatch(toggleDrawer(false))}
            >
              <Box width={250} py={2}>
                <Logo
                  sx={{
                    marginX: "1rem",
                    width: "fit-content",
                  }}
                />
                {!user && (
                  <Link
                    to={linkWithLanguage("/")}
                    onClick={() => dispatch(toggleDrawer(false))}
                  >
                    <MenuItem component="ul" sx={navbarItemStyle}>
                      {t("common.ourPrograms")}
                    </MenuItem>
                  </Link>
                )}
                <NavbarItems user={user} dispatch={dispatch} />
              </Box>
            </Drawer>
          </Box>
          <Logo sx={{ display: "flex", flexGrow: { xs: 1, md: 0 } }} />
          {/* Web View  Items */}
          <Box {...navbarItemsProps}>
            <NavbarItems user={user} />
          </Box>

          {/* Right 3 icons */}
          <Box display={"flex"} alignItems={"center"}>
            {!user && (
              <Button
                component={Link}
                to={linkWithLanguage(commonLinks.homeLink)}
                sx={{
                  color: defaultColors.dark0,
                  fontWeight: 600,
                  textTransform: "none",
                  display: { xs: "none", md: "block" },
                  textAlign: "center",
                }}
              >
                {t("common.ourPrograms")}
              </Button>
            )}
            <LanguageSwitcherButton />
            <CartIcon basket={basket} history={history} />
            {user ? (
              <Logout />
            ) : (
              <IconButton onClick={onLoginClick}>
                <PermIdentityOutlinedIcon />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </CustomContainer>
    </AppBar>
  );
}
export default Navbar;

function NavbarItems({ user }) {
  if (!user) return null;
  const {
    is_lsv_usher,
    is_applicant,
    is_thirdParty_supervisor,
    is_company,
    is_loyac_staff,
    is_parent,
    is_community_center,
  } = user;

  return (
    <>
      {is_lsv_usher && localStorage.getItem("user_type") === "is_lsv_usher" ? (
        <UsherOptions />
      ) : is_applicant && !is_thirdParty_supervisor ? (
        <ApplicantOptions />
      ) : is_company ? (
        <CompanyOptions />
      ) : is_loyac_staff ? (
        <LoyacStaffOptions />
      ) : is_parent ? (
        <ParentOptions />
      ) : is_thirdParty_supervisor ? (
        <SupervisorOptions />
      ) : is_community_center ? (
        <CommunityCenterOptions />
      ) : null}
    </>
  );
}

const responsiveBoxProps = {
  sx: { display: { xs: "flex", md: "none" } },
};
const navbarItemsProps = {
  sx: {
    display: { xs: "none", md: "flex" },
    flexGrow: { xs: 0, md: 1 },
  },
};
