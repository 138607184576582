import axios from "redux/actions/instance";

const registrationUri = "/v2/reg/registrations/";

export const patchRegistrationAPI = (id: number, payload: any, query?: any) =>
  axios.patch(`${registrationUri}${id}/${query}`, payload);

export const bulkDarbiUpdateAPI = (payload: any) =>
  axios.post(`/v2/reg/update/bulk/darbi/`, payload);

export const parentRegisterChildrenAPI = (payload: any) =>
  axios.post(`v2/reg/parent/children/registration/`, payload);

export const parentBasketAPI = (ids: any) =>
  axios.get(`v2/reg/parent/basket/?ids=${ids}`);

export const parentRegistrationCheckoutAPI = (payload: any) =>
  axios.post(`v2/reg/parent/checkout/`, payload);

export const parentApplyCouponAPI = (payload: any) =>
  axios.post(`v2/reg/parent/apply/coupon/`, payload);

export const parentRemoveCouponAPI = (payload: any) =>
  axios.post(`v2/reg/parent/remove/coupon/`, payload);
