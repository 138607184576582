// Actions
import {
    FETCH_ACTIVITY_REGISTRATION,
    FETCH_ACTIVITY_REGISTRATIONS,
  } from "../actions/actionTypes";
  
  const initialState = {
    registrations: [],
    filteredRegistrations: [],
    registrationLoading: true,
  };
  
  export const activityRegistrationReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ACTIVITY_REGISTRATION:
        return {
          ...state,
          registrationsac: action.payload,
          registrationsacLoading: false,
        };
  
      case FETCH_ACTIVITY_REGISTRATIONS:
        return {
          ...state,
          registrations: action.payload[1],
          filteredRegistrations: action.payload[0],
          registrationLoading: false,
        };
  
      default:
        return state;
    }
  };
  
  export default activityRegistrationReducer;
  