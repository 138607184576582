import { useSelector } from "react-redux";
import CommonNavigation from "../CommonNavigation";
import {
  NavbarOptionsNode,
  commonNavigationLinks,
  commonTitles,
} from "./IOption";
import { useTranslation } from "react-i18next";

function ApplicantOptions() {
  const { user } = useSelector((state: any) => state.rootAuth);
  const { t } = useTranslation();
  const translation = (key: string) => t(`navigation.applicant.${key}`);
  const data: NavbarOptionsNode[] = [
    commonNavigationLinks(t).programs,

    commonTitles(t, `/applicant/${user.user_id}/history/`).history,
    {
      title: translation("faq"),
      to: `/FAQ/`,
    },
    {
      title: translation("contactUs"),
      to: `/applicant/${user.user_id}/threads/`,
    },
  ];
  return <CommonNavigation data={data} />;
}

export default ApplicantOptions;
