import { SET_USHER_OBJ } from "../actions/actionTypes";

const initialState = {
  usherObj: null,
};

export const UsherReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USHER_OBJ:
      return {
        ...state,
        usherObj: action.payload,
      };
    default:
      return state;
  }
};

export default UsherReducer;
