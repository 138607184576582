// Actions
import { FETCH_COMPANY_EVALUATION } from "../actions/actionTypes";

const initialState = {
  companyEvaluation: null,
  companyEvaluationLoading: true,
};

export const evaluationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANY_EVALUATION:
      return {
        ...state,
        companyEvaluation: action.payload,
        companyEvaluationLoading: false,
      };

    default:
      return state;
  }
};

export default evaluationsReducer;
