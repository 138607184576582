import React, { useEffect, useState } from "react";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { dequeueAlert } from "redux/slices/alertSlice";
import { useAppDispatch, useAppSelector } from "redux/reducers/hooks";

const AlertComponent = () => {
  const dispatch = useAppDispatch();
  const { alertQueue } = useAppSelector(({ alert }) => alert);

  const [currentAlert, setCurrentAlert] = useState<any>(null);

  useEffect(() => {
    if (alertQueue.length > 0) {
      setCurrentAlert(alertQueue[0]);
    } else {
      setCurrentAlert(null);
    }
  }, [alertQueue]);

  const handleClose = () => {
    dispatch(dequeueAlert());
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={currentAlert !== null}
        autoHideDuration={12000}
        onClose={handleClose}
      >
        <Alert severity={currentAlert?.severity} onClose={handleClose}>
          {currentAlert?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AlertComponent;
