import { createTheme } from "@mui/material/styles";
import createCache from "@emotion/cache";

export const defaultColors = {
  light0: "#ffffff",
  dark0: "#3b3a3b",
  darkGreen: "#275D38",
  darkGrey: "#282829",
  primary: "#00a84f",
  orange: "#fd854c",
  yellow: "#f1c050",
  blue: "#58c5e1",
  green: "#0bb956",
};

export const cacheLtr = createCache({
  key: "muiltr",
});

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } });

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: defaultColors.primary, // Replace this with your desired primary color
    },
    //@ts-ignore
    yellow: createColor("#ffd649"),
  },

  typography: {
    fontFamily: "Titillium Web, sans-serif", // Replace 'YourFontFamily' with the actual font you want to use
  },

  components: {
    // Custom styles for MUI tables
    //@ts-ignore
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: `${defaultColors.dark0} !important`,
          color: `${defaultColors.light0} !important`,
          textTransform: "uppercase",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
            {
              marginBottom: 0,
            },
        },
      },
    },
  },
});

export default theme;
