// Actions
import {
  FETCH_REQUESTS,
  FILTER_REQUESTS,
  FETCH_REQUEST_DETAILS,
  FETCH_BRANCH_REQUESTS,
  FETCH_REQUEST_PROGRAM,
} from "../actions/actionTypes";

const initialState = {
  requests: null,
  filteredRequests: [],
  requestsLoading: true,
  request: null,
  branches: [],
  program: null,
};

export const requestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REQUESTS:
      return {
        ...state,
        requests: action.payload,
        filteredRequests: action.payload,
        requestsLoading: false,
      };
    case FETCH_REQUEST_DETAILS:
      return {
        ...state,
        request: action.payload,
      };
    case FETCH_REQUEST_PROGRAM:
      return {
        ...state,
        program: action.payload,
      };
    case FETCH_BRANCH_REQUESTS:
      return {
        ...state,
        branches: action.payload,
      };
    case FILTER_REQUESTS:
      return {
        ...state,
        filteredRequests: state.requests.filter((request) => {
          return `${request.company.name}`
            .toLowerCase()
            .includes(action.payload.toLowerCase());
        }),
      };
    default:
      return state;
  }
};

export default requestsReducer;
