import moment from "moment";

import instance from "./instance";

import { resetErrors, handleError } from "../actions";

export const FetchInitiativesList = () => async () => {
  try {
    const response = await instance.get(`/initiatives/`);
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchInitiative = (initiativeID) => async () => {
  try {
    const response = await instance.get(`/initiatives/${initiativeID}/`);
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const NewInitiative = (initiativeData) => async (dispatch) => {
  try {
    const formData = new FormData();
    Object.keys(initiativeData).forEach((key) => {
      formData.append(key, initiativeData[key]);
    });
    const response = await instance.post("/initiatives/add/", formData);
    dispatch(resetErrors());
    return response.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const EditInitiative = (initiativeData) => async () => {
  try {
    const formData = new FormData();
    Object.keys(initiativeData).forEach((key) => {
      formData.append(key, initiativeData[key]);
    });
    // remove image if it didn't get updated.
    if (typeof formData.get("image") === "string") formData.delete("image");
    await instance.put(`/initiatives/${initiativeData.id}/update/`, formData);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchDocsList = (initiativeID) => async () => {
  try {
    const response = await instance.get(`/initiatives/${initiativeID}/docs/`);
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const NewDoc = (initiativeID, DocsList) => async (dispatch) => {
  try {
    await Promise.all(
      DocsList.map(async (data) => {
        const docData = { ...data, initiative: initiativeID };
        const formData = new FormData();
        Object.keys(docData).forEach((key) => {
          formData.append(key, docData[key]);
        });
        await instance.post(`initiatives/${initiativeID}/doc/add/`, formData);
      })
    );
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const EditDoc = (docID, docData) => async (dispatch) => {
  try {
    const formData = new FormData();
    Object.keys(docData).forEach((key) => {
      formData.append(key, docData[key]);
    });
    // remove file if it didn't get updated.
    if (typeof formData.get("file") === "string") formData.delete("file");
    await instance.put(`/docs/${docID}/`, formData);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const DeleteDoc = (docID) => async () => {
  try {
    await instance.delete(`/docs/${docID}/`);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchRolesList = (initiativeID) => async () => {
  try {
    const response = await instance.get(`/initiatives/${initiativeID}/roles/`);
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const NewRole = (initiativeID, RolesList) => async (dispatch) => {
  try {
    await Promise.all(
      RolesList.map(async (data) => {
        const roleData = { ...data, initiative: initiativeID };
        const formData = new FormData();
        Object.keys(roleData).forEach((key) => {
          formData.append(key, roleData[key]);
        });
        await instance.post(`initiatives/${initiativeID}/role/add/`, formData);
      })
    );
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const EditRole = (roleID, roleData) => async () => {
  try {
    await instance.put(`/roles/${roleID}/`, { ...roleData });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const DeleteRole = (roleID) => async () => {
  try {
    await instance.delete(`/roles/${roleID}/`);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchShiftsList = (initiativeID) => async () => {
  try {
    const response = await instance.get(`/initiatives/${initiativeID}/shifts/`);
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const NewShift = (initiativeID, ShiftsList) => async (dispatch) => {
  try {
    await Promise.all(
      ShiftsList.map(async (data) => {
        const shiftData = { ...data, initiative: initiativeID };
        const formData = new FormData();
        Object.keys(shiftData).forEach((key) => {
          formData.append(key, shiftData[key]);
        });
        await instance.post(`initiatives/${initiativeID}/shift/add/`, formData);
      })
    );
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const EditShift = (shiftID, shiftData) => async () => {
  try {
    await instance.put(`/shifts/${shiftID}/`, { ...shiftData });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const DeleteShift = (shiftID) => async () => {
  try {
    await instance.delete(`/shifts/${shiftID}/`);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchLocations = (showAll) => async () => {
  try {
    const response = await instance.get(`/locations/?show_all=${showAll}`);
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const EditLocation = (locationID, locationData) => async () => {
  try {
    await instance.put(`/locations/${locationID}/`, { ...locationData });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const EditSubLocations =
  (subLocationsList, locationID) => async (dispatch) => {
    try {
      await instance.put(`/locations/${locationID}/sub-location/edit/`, {
        subLocationsList,
      });
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const NewInterviewSlots = (data) => async (dispatch) => {
  try {
    const array = [];
    data.map((obj) => {
      let startTime = moment(obj.starting_time, "HH:mm");

      let endTime = moment(obj.ending_time, "HH:mm");

      while (startTime < endTime) {
        const timestamp = moment(
          obj.date + " " + moment(startTime).format("HH:mm"),
          "YYYY-MM-DD HH:mm"
        );
        array.push({
          timestamp: timestamp.format("YYYY-MM-DDTHH:mm"),
          capacity: obj.capacity,
          platform: obj.platform,
          location: obj.location,
          location_details: obj.location_details,
        });
        startTime.add(30, "minutes");
      }
      return null;
    });

    await Promise.all(
      array.map((obj) => {
        instance.post("/LSV/slots/add/", obj);
        return null;
      })
    );
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const FetchLSVInterviewSlots = () => async () => {
  try {
    const response = await instance.get(`/LSV/slots/no-pagination/`);
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const DeleteInterviewSlot = (slotID) => async () => {
  try {
    await instance.delete(`/LSV/slots/${slotID}/`);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchSubLocations = () => async () => {
  try {
    const response = await instance.get("/sub-locations/");
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
