import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  drawerOpen: false,
  handleTopClose: undefined,
};

const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    toggleDrawer: (state, action: PayloadAction<any>) => {
      state.drawerOpen = action.payload;
    },
    sethandleTopClose: (state, action: PayloadAction<any>) => {
      state.handleTopClose = action.payload;
    },
  },
});

export const { toggleDrawer, sethandleTopClose } = navbarSlice.actions;

export default navbarSlice.reducer;
