import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { GET_USER_FOR_APPLICANT } from "redux/actions/graphqlQueries/applicant";
import { apolloClient } from "apolloSettings";
import { apiErrorCatcher } from "./common-action-utils";
import { parentCreateChildrenAPI } from "redux/services/applicants";
import { getCreditsAPI, patchCreditAPI } from "redux/services/applicants";
import { createQuery } from "./common-action-utils";

export const initialState = {
  loading: false,
  formSuccess: false,
  applicant: null,
  error: null,
  credits: null,
  creditFilters: null,
  formLoading: false,
};

export const getApplicant = createAsyncThunk(
  "applicant/getApplicant",
  async (variables?: {}, thunkAPI?: any) => {
    try {
      const response = await apolloClient.query({
        query: GET_USER_FOR_APPLICANT,
        variables:
          {
            ...variables,
          } || {},
        fetchPolicy: "no-cache", // Add this line to bypass the cache
      });
      return response.data.user.applicant;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const parentCreateChildren = createAsyncThunk(
  "applicant/parentCreateChildren",
  async (payload: any, thunkAPI) => {
    try {
      const response = await parentCreateChildrenAPI(payload);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getCredits = createAsyncThunk(
  "applicants/getCredits",
  async (payload?: {}, thunkAPI?: any) => {
    const { creditFilters } = thunkAPI.getState().rootApplicantSlice; // Access current state here
    const queryParams = { ...creditFilters, ...payload };
    const query = "?" + createQuery({ filter: queryParams });
    try {
      const response = await getCreditsAPI(query);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const patchCredit = createAsyncThunk(
  "applicants/patchCredit",
  async (payload: any, thunkAPI) => {
    const { id, data } = payload;

    try {
      const response = await patchCreditAPI(id, data);
      return response.data;
    } catch (error: any) {
      apiErrorCatcher(error, thunkAPI.dispatch);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const applicantSlice = createSlice({
  name: "applicantSlice",
  initialState,
  reducers: {
    setApplicant(state, action: PayloadAction<any>) {
      state.applicant = action.payload;
    },
    applyCreditFilters(state, action: PayloadAction<any>) {
      state.creditFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicant.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApplicant.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.applicant = action.payload;
      })
      .addCase(getApplicant.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //parentCreateChildren
      .addCase(parentCreateChildren.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(
        parentCreateChildren.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = null;
          state.formSuccess = true;
        }
      )
      .addCase(
        parentCreateChildren.rejected,
        (state, action: PayloadAction<any>) => {
          state.formLoading = false;
          state.error = action.payload;
          state.formSuccess = false;
        }
      )

      // get credits
      .addCase(getCredits.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCredits.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.credits = action.payload;
        state.error = null;
      })
      .addCase(getCredits.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle the approvedCoreProgram async thunk
      .addCase(patchCredit.pending, (state) => {
        state.formLoading = true;
      })
      .addCase(patchCredit.fulfilled, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = null;
      })
      .addCase(patchCredit.rejected, (state, action: PayloadAction<any>) => {
        state.formLoading = false;
        state.error = action.payload;
      });
  },
});

// // Action creators are generated for each case reducer function
export const { setApplicant, applyCreditFilters } = applicantSlice.actions;

export default applicantSlice.reducer;
