import styled from "styled-components";

const LoadingPageStyles = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;

  img {
    width: 220px;
    height: 80px;
  }
`;

export default LoadingPageStyles;
