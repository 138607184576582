// Actions
import {
  FETCH_COMMUNITY_CENTER,
  SET_COMMUNITY_CENTER,
} from "../actions/actionTypes";

const initialState = {
  communitycenters: [],
  loading: true,
};

export const communityCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMUNITY_CENTER:
      return {
        ...state,
        communitycenters: action.payload,
        loading: false,
      };

    case SET_COMMUNITY_CENTER:
      return {
        ...state,
        communitycenter: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default communityCenterReducer;
