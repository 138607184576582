export const ignoreErrors = [
  "Canvas is empty",
  "undefined is not an object (evaluating 'a.O')",
  "undefined is not an object (evaluating '__gCrWeb.translate.handleResponse')",
  "Loading chunk",
  "ResizeObserver loop",
  "unsupported number: NaN",
  "Loading CSS",
  "NotFoundError",
  "readableAddChunk(readable-stream/lib/_stream_readable",
  "t.cleanup(src/elements/Node)",
];
