// Actions
import { GET_FORMS, GET_SUPERVISORS } from "../actions/actionTypes";

const initialState = {
  supervisors: [],
  supervisorLoading: true,
  forms: [],
  formsLoading: true,
};

export const supervisorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPERVISORS:
      return {
        ...state,
        supervisors: action.payload,
        supervisorLoading: false,
      };
    case GET_FORMS:
      return {
        ...state,
        forms: action.payload,
        formsLoading: false,
      };

    default:
      return state;
  }
};

export default supervisorReducer;
