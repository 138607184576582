//@ts-nocheck

import React from "react";
import { Dialog, DialogActions, DialogContent, Zoom } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
/**
 * DialogBase component - A reusable dialog component with customizable content, actions, and transitions.
 *
 * @param {boolean} open - Controls whether the dialog is open or closed.
 * @param {Function} hideDialog - Function to hide the dialog (usually changes the `open` state).
 * @param {ReactNode} content - The content to display inside the dialog.
 * @param {ReactNode} actions - The actions (buttons) to display at the bottom of the dialog.
 * @param {string} title - The title text to display at the top of the dialog.
 * @param {string} [subheader] - Optional subheader text displayed in the Cardheader component.
 * @param {object} [contentProps] - Additional props to be passed to the `DialogContent` component.
 * @param {Function} onClose - Function to handle the closing of the dialog.
 * @param {React.ComponentType} [TransitionComponent] - Optional transition component for the dialog's open/close animation.
 * @param {boolean} [disableDefaultClose=false] - If true, prevents the dialog from closing when clicking outside or pressing the escape key.
 * @param {object} restProps - Any additional props that will be spread onto the root element.

 */
const DialogBase = ({
  open,
  hideDialog,
  content,
  actions,
  title,
  subheader,
  contentProps,
  onClose,
  TransitionComponent,
  disableDefaultClose,
  ...restProps
}) => {
  const handleClose = () => {
    onClose();
    if (!disableDefaultClose) hideDialog();
  };

  return (
    <Dialog
      open={open}
      {...restProps}
      onClose={handleClose}
      TransitionComponent={TransitionComponent}
    >
      {title && (
        <CardHeader title={title} subheader={subheader} sx={{ pb: 0 }} />
      )}
      <DialogContent {...contentProps}>{content}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

DialogBase.defaultProps = {
  onClose: () => {},
  TransitionComponent: Zoom,
  disableDefaultClose: false,
};

export default DialogBase;
