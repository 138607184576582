import * as actionTypes from "./actionTypes";

export const setErrors = (errors) => ({
  type: actionTypes.SET_ERRORS,
  payload: errors,
});

export const resetErrors = () => async (dispatch) => {
  await dispatch(setErrors({}));
};

export const handleError = (error) => async (dispatch) => {
  if (error.response) {
    const data = error?.response?.data;
    let errors = "";

    if (typeof data === "string") {
      errors = [`Error: ${data}`];
    } else {
      errors = Object.keys(error.response.data).map((key) => {
        return (
          key
            .replace(/_/g, " ")
            .split(" ")
            .map((word) => word[0].toUpperCase() + word.substring(1))
            .join(" ") +
          ": " +
          error.response.data[key]
        );
      });
    }

    await dispatch(setErrors(errors));
    return errors;
  } else {
    console.error(error);
  }
};
