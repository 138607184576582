import { Button, IconButton, Menu } from "@mui/material";
import { customTextButton } from "components/styles";
import React, { useState } from "react";

function MuiMenu({
  children,
  title,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  leftShift = 0,
  isIconButton = false,
  width = "100%",
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const menu = document.getElementById("mui_menu_menu");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (menu) {
      menu.style.visibility = "visible";
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    // if (menu) {
    //   menu.style.visibility = "hidden";
    // }
  };
  const BtnComponent = isIconButton ? IconButton : Button;
  return (
    <div>
      <BtnComponent
        id="mui_menu_button"
        aria-controls={open ? "mui_menu_menu" : undefined}
        onClick={handleClick}
        sx={{
          ...customTextButton,
          width,
          ...(!isIconButton && {
            paddingLeft: "1rem",
            paddingY: "0.7rem",
          }),
        }}
      >
        {typeof title === "function" ? title(open) : title}
      </BtnComponent>
      <Menu
        autoFocus={false}
        disableScrollLock={true}
        id="mui_menu_menu"
        aria-labelledby="mui_menu_button"
        anchorEl={anchorEl}
        open={open}
        elevation={1}
        onClose={handleClose}
        sx={{ marginLeft: `${leftShift}px` }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {children && typeof children === "function" && children(handleClose)}{" "}
      </Menu>
    </div>
  );
}

export default MuiMenu;
