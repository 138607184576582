import axios from "redux/actions/instance";

export const getCoreProgramsAPI = (query?: any) =>
  axios.get(`/core/programs/${query}`);

export const createCoreProgramAPI = (payload: any) =>
  axios.post(`/core/programs/`, payload);

export const updateCoreProgramAPI = (id: number, payload: any) =>
  axios.put(`/core/programs/${id}/`, payload);

export const approvedCoreProgramAPI = (id: number, payload: any) =>
  axios.patch(`/core/programs/${id}/`, payload);
