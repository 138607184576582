import instance from "./instance";
import * as actionTypes from "./actionTypes";

import { resetErrors, handleError, getCompany } from "../actions";

export const fetchRequests =
  (pageNumber, companyID, requestID, branchID) => async (dispatch) => {
    try {
      let res;
      if (companyID)
        res = await instance.get(
          `/requests/?page=${pageNumber}&company=${companyID}`
        );
      else if (requestID)
        res = await instance.get(
          `/requests/?page=${pageNumber}&request=${requestID}`
        );
      else if (branchID)
        res = await instance.get(
          `/requests/?page=${pageNumber}&branch=${branchID}`
        );
      else res = await instance.get(`/requests/?page=${pageNumber}`);
      const requests = res.data;
      dispatch({ type: actionTypes.FETCH_REQUESTS, payload: requests });
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const FetchRequestDetails = (requestID) => async (dispatch) => {
  try {
    const result = await instance.get(`/requests/new/${requestID}/`);
    dispatch({ type: actionTypes.FETCH_REQUEST_DETAILS, payload: result.data });
    return result.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchAssignedInternsList = (programID, companyID) => async () => {
  try {
    const response = await instance.get(
      `/internsList/${programID}/${companyID}/`
    );
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchParticipatedCompanies =
  (registrationID, companyException, genderException) => async () => {
    try {
      const res = await instance.get(
        `/companiesList/${registrationID}/?company_exception=${companyException}&gender_exception=${genderException}`
      );
      return res.data;
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const filterRequests = (query) => {
  return {
    type: actionTypes.FILTER_REQUESTS,
    payload: query,
  };
};

export const NewRequest = (requestData, history) => async (dispatch) => {
  try {
    const response = await instance.post("/requests/", requestData);

    const request = response.data;
    if (requestData["next_step"])
      history.push(`/requests/${request.id}/branches/add/`);
    else history.push("/requests/");

    dispatch(resetErrors());
  } catch (error) {
    await dispatch(handleError(error));
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const UpdateRequestDetails =
  (requestID, requestData, history) => async (dispatch) => {
    try {
      const response = await instance.put(
        `/requests/${requestID}/`,
        requestData
      );

      const request = response.data;

      if (history)
        if (requestData["next_step"])
          history.push(`/requests/${request.id}/branches/add/`);
        else history.push("/requests/");

      dispatch(resetErrors());
    } catch (error) {
      await dispatch(handleError(error));
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const UpdateRequestStatus =
  (requestID, status, withdraw_reasons) => async (dispatch) => {
    try {
      await instance.patch(`/requests/${requestID}/`, {
        status,
        withdraw_reasons,
      });
      window.location.reload();
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const deleteRequest = (requestID) => async (dispatch) => {
  try {
    await instance.delete(`/requests/${requestID}/`);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const fetchBranchRequest = (branchID) => async (dispatch) => {
  try {
    const res = await instance.get(`/branch/${branchID}/`);
    const branch = res.data;

    return branch;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
export const FetchRequestBranches = (requestID) => async (dispatch) => {
  try {
    const res = await instance.get(`/request/${requestID}/branches/`);
    const branch = res.data;
    dispatch({ type: actionTypes.FETCH_BRANCH_REQUESTS, payload: branch });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const AddBranchRequest =
  (requestID, BranchesList, requestType, history) => async () => {
    try {
      await Promise.all(
        BranchesList.map((data) => {
          let formData = new FormData();
          Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
          });
          if (typeof formData.get("job_description_file") === "string")
            formData.delete("job_description_file");
          return instance.post(`/branch/${requestID}/add/`, formData);
        })
      );

      await instance.put(`/requests/${requestID}/`, {
        request_type: requestType,
        steps_complete: 2,
      });

      history.push("/requests/");
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const updateBranchRequest = (branchID, status) => async (dispatch) => {
  try {
    await instance.patch(`/branch/${branchID}/`, { status });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const deleteBranchRequest = (branchID) => async (dispatch) => {
  try {
    await instance.delete(`/branch/${branchID}/`);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
export const VolnteerRequest =
  (requestData, userID, history) => async (dispatch) => {
    try {
      const res = await instance.post("/requests/", requestData);
      const request = res.data;
      await dispatch(getCompany(userID));
      dispatch(resetErrors());
      history.push("/requests/");
      return request;
    } catch (error) {
      await dispatch(handleError(error));
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };
