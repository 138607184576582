import axios from "redux/actions/instance";

const v2ApplicantUri = "/v2/applicant/";

export const parentCreateChildrenAPI = (payload: any) =>
  axios.post(`${v2ApplicantUri}create/children/`, payload);

export const getCreditsAPI = (query?: any, responseType?: any) => {
  const url = query
    ? `${v2ApplicantUri}credits/${query}`
    : `${v2ApplicantUri}credits/`;

  if (responseType) {
    return axios.get(url, { responseType: responseType });
  }
  return axios.get(url);
};

export const patchCreditAPI = (id: number, payload: any) =>
  axios.patch(`${v2ApplicantUri}credits/${id}/`, payload);
