import instance from "./instance";
import * as actionTypes from "./actionTypes";

// actions
import { resetErrors, handleError } from "../actions";

export const FetchActivities = () => async (dispatch) => {
  try {
    const res = await instance.get(`/activities/`);
    const activities = res.data;

    dispatch({ type: actionTypes.FETCH_ACTIVITIES, payload: activities });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchActivity = (activityID) => async (dispatch) => {
  try {
    const res = await instance.get(`/activity/${activityID}/`);
    const activity = res.data;

    dispatch({ type: actionTypes.FETCH_ACTIVITY, payload: activity });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchActivitiesSearch = () => async (dispatch) => {
  try {
    const res = await instance.get(`/staff/activities/`);
    const activities = res.data;
    return activities;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const AddActivity = (programData, history) => async (dispatch) => {
  try {
    const formData = new FormData();
    Object.keys(programData).forEach((key) => {
      formData.append(key, programData[key]);
    });

    // Remove files if they hadn't been update, because their format will be wrong.
    if (typeof formData.get("image") === "string") formData.delete("image");
    if (typeof formData.get("arabic_image") === "string")
      formData.delete("arabic_image");
    if (typeof formData.get("timeline") === "string")
      formData.delete("timeline");
    if (typeof formData.get("arabic_timeline") === "string")
      formData.delete("arabic_timeline");
    if (typeof formData.get("trainer_image") === "string")
      formData.delete("trainer_image");
    if (typeof formData.get("contract") === "string")
      formData.delete("contract");
    if (typeof formData.get("contract_ar") === "string")
      formData.delete("contract_ar");

    await instance.post(`/activities/`, formData);
    dispatch(resetErrors());

    history.push("/activities/");
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const UpdateActivity =
  (activityID, activityData, history) => async (dispatch) => {
    try {
      const formData = new FormData();
      Object.keys(activityData).forEach((key) => {
        formData.append(key, activityData[key]);
      });

      // Remove fields if they hadn't been update, because their format will be wrong.
      if (typeof formData.get("image") === "string") formData.delete("image");
      if (typeof formData.get("arabic_image") === "string")
        formData.delete("arabic_image");
      if (typeof formData.get("timeline") === "string")
        formData.delete("timeline");
      if (typeof formData.get("arabic_timeline") === "string")
        formData.delete("arabic_timeline");
      if (typeof formData.get("trainer_image") === "string")
        formData.delete("trainer_image");

      await instance.put(`/activities/${activityID}/`, formData);
      if (history) history.push(`/activities/${activityID}/`);
    } catch (error) {
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const AddUpdateAttendanceActivity =
  (activityID, date, attendanceList) => async (dispatch) => {
    try {
      await Promise.all(
        attendanceList
          .filter((attendance) => !!attendance["status"])
          .map((attendance) => {
            attendance["date"] = new Date(
              date.getTime() - date.getTimezoneOffset() * 60000
            ).toISOString();
            return instance.post(
              `/activities/${activityID}/attendance/`,
              attendance
            );
          })
      );

      await dispatch(FetchActivity(activityID));

      dispatch(resetErrors());
    } catch (error) {
      dispatch(handleError(error));
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const simpleActivityFetching = (activityID) => async (dispatch) => {
  try {
    const res = await instance.get(
      `/activities/${activityID}/simple_fetching/`
    );
    const activity = res.data;
    return activity;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
