import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GetSupervisorAssignedCompany } from "redux/actions";
import CommonNavigation from "../CommonNavigation";
import {
  NavbarOptionsNode,
  commonNavigationLinks,
  commonTitles,
} from "./IOption";

function SupervisorOptions() {
  const [assignedCompanies, setAssignedCompanies] = useState([]);
  const { user, supervisor } = useSelector((state: any) => state.rootAuth);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      if (user.user_id) {
        const companiesList = await dispatch(
          GetSupervisorAssignedCompany(user.user_id)
        );
        setAssignedCompanies(companiesList);
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const translate = (key: string) => t(`navigation.supervisors.${key}`);
  const translation = (key: string) => t(`navigation.loyacStaff.${key}`);
  const data: NavbarOptionsNode[] = [
    commonNavigationLinks(t).programs,
    ...(assignedCompanies && assignedCompanies.length > 0
      ? [
          {
            title: translate("assignedCompanies"),
            isChildren: true,
            children: assignedCompanies?.map((company: any) => ({
              title: company?.company_name,
              to: `/company/${company?.user_id}/profile/`,
            })),
          },
        ]
      : []),
    {
      title: translation("lists"),
      isChildren: true,
      children: [
        {
          title: translation("registrationLists"),
          isChildren: true,
          children: [
            commonTitles(t, "/registrations/").publicProgram,
            commonTitles(t, "/registrations/activities/").exclusiveProgram,
            commonTitles(t, "/ushers/").lsvInitiative,
          ],
        },
        ...(supervisor?.permissions?.can_view_company_requests
          ? [commonNavigationLinks(t).requestsList]
          : []),
        ...(supervisor?.permissions?.can_view_invitations_list
          ? [commonNavigationLinks(t).invitationsList]
          : []),
      ],
    },

    ...(supervisor?.permissions?.can_respond_to_threads
      ? [commonNavigationLinks(t).commonThreads]
      : []),
  ];
  return <CommonNavigation data={data} />;
}

export default SupervisorOptions;
