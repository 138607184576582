import instance from "./instance";
import * as actionTypes from "./actionTypes";

// Actions
import { handleError, resetErrors } from "../actions";
import { handleFileDownload } from "redux/slices/common-action-utils";

export const setCompany = (company) => ({
  type: actionTypes.SET_COMPANY,
  payload: company,
});

export const getCompany = (userID) => async (dispatch) => {
  try {
    const res = await instance.get(`/companies/${userID}/`);
    const company = res.data;

    dispatch(setCompany(company));
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const GetSimplifiedCompaniesList = (programID) => async () => {
  try {
    const response = await instance.get(
      `/companies/simple/?program_id=${programID}`
    );
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const simpleCompanyFetching = (companyID) => async (dispatch) => {
  try {
    let company_id = companyID;
    if (companyID.id) {
      company_id = companyID.id;
    }
    const res = await instance.get(`/company/${company_id}/`);
    const company = res.data;

    return company;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const updateCompany =
  (userID, companyData, history) => async (dispatch) => {
    try {
      const formData = new FormData();
      Object.keys(companyData).forEach((key) => {
        key !== "address" && key !== "contact_person" && key !== "user"
          ? formData.append(key, companyData[key])
          : formData.append(key, JSON.stringify(companyData[key]));
      });

      // Remove files if they hadn't been update, because their format will be wrong.
      if (typeof formData.get("logo") === "string") formData.delete("logo");

      const res = await instance.put(`/companies/${userID}/`, formData);

      const response = res.data;
      const company = response.company.data;

      await dispatch(setCompany(company));
      dispatch(resetErrors());
      if (history) history.push(`/company/${userID}/profile/`);
    } catch (error) {
      dispatch(handleError(error));
      error.response
        ? console.error(error.response.data)
        : console.error(error);
    }
  };

export const fetchCompanies = () => async (dispatch) => {
  try {
    const res = await instance.get(`/companies/`);
    const companies = res.data;

    dispatch({ type: actionTypes.FETCH_COMPANIES, payload: companies });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const addInvitee = (data) => async (dispatch) => {
  try {
    data["supervisorCode"] = localStorage.getItem("supervisor_code");
    await instance.post(`/invitee/add/`, data);
    dispatch(resetErrors());
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const addInvitation = (inviteesList, program) => async (dispatch) => {
  try {
    const data = inviteesList.map((obj) => {
      return { program, invitee: obj.id, email: obj.email, cc: obj.cc };
    });
    await instance.post(
      `/invitation/add/?supervisorCode=${localStorage.getItem(
        "supervisor_code"
      )}`,
      data
    );
    dispatch(resetErrors());
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateInvitee = (data) => async (dispatch) => {
  try {
    data["supervisorCode"] = localStorage.getItem("supervisor_code");
    await instance.put(`invitee/${data.id}/update/`, data);
    dispatch(resetErrors());
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateInvitation = (data) => async (dispatch) => {
  try {
    data["supervisorCode"] = localStorage.getItem("supervisor_code");
    await instance.put(`invitation/${data.id}/update/`, data);
    dispatch(resetErrors());
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const resendInvitation = (data) => async (dispatch) => {
  try {
    data["supervisorCode"] = localStorage.getItem("supervisor_code");
    await instance.put(`invitation/${data.id}/resend-invitation/`, data);
    dispatch(resetErrors());
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const exportInvitationList = (params) => async (dispatch) => {
  try {
    const response = await instance.get(`invitations/`, {
      params,
      responseType: "blob",
    });

    handleFileDownload(response);
  } catch (error) {
    dispatch(handleError(error));
  }
};
