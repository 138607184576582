import instance from "./instance";
import * as actionTypes from "./actionTypes";

// Actions

export const setStaff = (staff) => ({
  type: actionTypes.SET_STAFF,
  payload: staff,
});

export const getStaff = (userID) => async (dispatch) => {
  try {
    const res = await instance.get(`/staff/${userID}/`);
    const staff = res.data;

    dispatch(setStaff(staff));
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const updateStaff = (userID, staffData, history) => async (dispatch) => {
  try {
    let formData = new FormData();
    Object.keys(staffData).forEach((key) => {
      formData.append(key, staffData[key]);
    });
    const res = await instance.put(`/staff/${userID}/`, formData);

    const response = res.data;
    const staff = response.staff.data;

    dispatch(setStaff(staff));

    if (history) history.push(`/staff/${userID}/profile/`);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
