import MuiMenu from "components/common/components/mui_menu";
import { NavbarOptionsNode } from "../options/IOption";
import NavbarMenuItem from "./NavbarMenuItem";
import { navbarTitleItem } from "./NavbarMenu";
import { Divider } from "@mui/material";

const NavbarChildMenu = (child: NavbarOptionsNode, handleTopCloseFunc: any) => {
  return (
    <div>
      {child.children?.map((subChild, index) => {
        let item;
        if (subChild.isChildren) {
          item = (
            <MuiMenu
              title={navbarTitleItem(subChild.title, subChild.badgeValue)}
            >
              {(_: Function) => NavbarChildMenu(subChild, handleTopCloseFunc)}
            </MuiMenu>
          );
        } else {
          item = (
            <NavbarMenuItem
              child={subChild}
              handleTopCloseFunc={handleTopCloseFunc}
            />
          );
        }
        return (
          <div key={index}>
            {item}
            {index + 1 === child.children?.length ? null : <Divider />}
          </div>
        );
      })}
    </div>
  );
};

export default NavbarChildMenu;
