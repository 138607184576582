import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import { Badge, IconButton } from "@mui/material";
import { linkWithLanguage } from "components/helpers/commonLinks";
import { convertApolloNodeToFlatArray } from "components/helpers/helper_functions";
import { memo } from "react";

const CartIcon = memo(({ basket, history }) => {
  if (!basket) return null;

  const lines = convertApolloNodeToFlatArray(basket?.lines?.edges);
  // Use reduce to calculate the sum of the 'quantity' values
  const count = lines.reduce((sum, item) => sum + item.quantity, 0);

  const navigateToCart = () => history.push(linkWithLanguage("/checkout/"));

  return (
    <IconButton onClick={navigateToCart} sx={{ pr: 2 }}>
      <Badge badgeContent={count} color="primary">
        <ShoppingBasketOutlinedIcon />
      </Badge>
    </IconButton>
  );
});

export default CartIcon;
