// Actions
import { FETCH_COMPANIES } from "../actions/actionTypes";

const initialState = {
  companies: [],
  loading: true,
};

export const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPANIES:
      return {
        ...state,
        companies: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default companiesReducer;
