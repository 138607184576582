import { Box, Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import NavbarMenuItem from "./components/NavbarMenuItem";
import { NavbarOptionsNode } from "./options/IOption";
import { toggleDrawer } from "./slice";
import NavbarMenu from "./components/NavbarMenu";
interface Props {
  data: NavbarOptionsNode[];
}
function CommonNavigation({ data }: Props) {
  const dispatch = useDispatch();
  const onDrawerClose = () => dispatch(toggleDrawer(false));
  return (
    <>
      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        {data.map((main, index) => (
          <div key={index}>
            {RenderChidren(main, undefined, onDrawerClose)}
            {index !== main.children?.length! - 1 && (
              <Divider sx={{ display: { xs: "block", md: "none" } }} />
            )}
          </div>
        ))}
      </Box>
    </>
  );
}

function RenderChidren(
  node: NavbarOptionsNode,
  handleClose?: Function,
  onDrawerClose?: () => any
) {
  // If the item has no sublists
  if (!node.isChildren) {
    const closePopup = () => {
      if (handleClose) handleClose();
      if (onDrawerClose) onDrawerClose();
    };
    return <NavbarMenuItem child={node} handleTopCloseFunc={closePopup} />;
  }
  // If the item has dropdown
  return <NavbarMenu node={node} onDrawerClose={onDrawerClose} />;
}

export default CommonNavigation;
