import instance from "./instance";
import * as actionTypes from "./actionTypes";

export const FetchThreads = () => async (dispatch) => {
  try {
    const res = await instance.get("/threads/");
    const threads = res.data;
    dispatch({
      type: actionTypes.FETCH_THREADS,
      payload: threads,
    });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const fetchThread = (threadID) => async (dispatch) => {
  try {
    const res = await instance.get(`/threads/`);
    const threads = res.data;

    const thread = threads.find((thread) => thread.id === parseInt(threadID));

    dispatch({ type: actionTypes.FETCH_THREAD, payload: thread });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const AddThread = (threadData, userID, history) => async () => {
  try {
    const response = await instance.post(`/threads/`, threadData);
    return response.data.id;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const addMessage = (threadID, messageData) => async () => {
  try {
    await instance.post(`/threads/${threadID}/`, messageData);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const updateMessage = (threadID) => async () => {
  try {
    await instance.put(`/threads/${threadID}/`, {
      responded_to: true,
    });
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const FetchProgramsThreads = () => async () => {
  try {
    const response = await instance.get(`/programs_threads/`);
    return response.data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
